import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { TenantService } from 'projects/usorta-library/src/lib/services/tenant/tenant.service';

@Component({
  selector: 'app-session-ended',
  templateUrl: './session-ended.component.html',
  styleUrls: ['./session-ended.component.css']
})
export class SessionEndedComponent implements OnInit {
  siteInfo:any;
  logo:string;
  constructor(private tenantService:TenantService) { }

  ngOnInit(): void {
    var tenantInfo = localStorage.getItem("tenantInfo");
    if(tenantInfo != null){
      this.siteInfo = JSON.parse(tenantInfo);
      this.logo = this.siteInfo.applicationConfiguration.logo as string
    }else{
      this.tenantService.getAppsettings(environment.applicationId,environment.tenantId ?? undefined).subscribe((response)=>{
        this.siteInfo = response.content as any;
        console.log(this.siteInfo);
        this.logo = this.siteInfo.applicationConfiguration.logo as string;
  
      })
    }
  }

}
