import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { MessageItem } from '../../models/messageBox';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.css']
})
export class MessageItemComponent implements OnInit {
  @Input() messageItem:MessageItem;
  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  goToReplyUrl(item:MessageItem){
    this.router.navigate([item.replyUrl]);
  }

}
