import { NotLoggedGuard } from './../../../usorta-library/src/guards/not-logged.guard';
import { LoggedGuard } from './../../../usorta-library/src/guards/logged.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from 'projects/usorta-library/src/lib/components/login/login.component';
import { MainComponent } from 'projects/instructor/src/app/components/main/main.component';
import { CustomPageComponent } from './components/custom-page/custom-page.component';
import { CourseLobbyComponent } from './components/course-lobby/course-lobby.component';
import { LearnSessionComponent } from './components/learn-session/learn-session.component';
import { GuacamoleTerminalComponent } from './components/guacamole-terminal/guacamole-terminal.component';
import { ConnectedFromOtherComponent } from 'projects/usorta-library/src/lib/components/connected-from-other/connected-from-other.component';
import { SessionEndedComponent } from './components/learn-session/session-ended/session-ended.component';
import { PopoutComponent } from 'projects/usorta-library/src/lib/components/popout/popout.component';
import { LayoutEditorComponent } from './components/layout-editor/layout-editor.component';
import { GuacamoleMultiTerminalComponent } from './components/guacamole-multi-terminal/guacamole-multi-terminal.component';

const routerinstructor: Routes = [
  {path:'home',component:MainComponent,pathMatch:'full',canActivate:[LoggedGuard]},
  {path: 'login',component:LoginComponent,pathMatch:'full',canActivate:[NotLoggedGuard]},
  {path: 'login/:type',component:LoginComponent,pathMatch:'full',canActivate:[LoggedGuard]},
  {path:'',redirectTo:'home',pathMatch:'full'},
  {path:'page/:pageKey',component:CustomPageComponent},
  {path:'lobby/:course',component:CourseLobbyComponent},
  {path:'session/:session',component:LearnSessionComponent,canActivate:[LoggedGuard]},
  {path:'session/:session/completed',component:SessionEndedComponent,canActivate:[LoggedGuard]},
  {path:'remoteClient/:jwt/:vm/:key',component:GuacamoleTerminalComponent},
  {path:'remoteClient/:jwt/:vm',component:GuacamoleTerminalComponent},
  {path:'sessionkicked/:sessionId',component:ConnectedFromOtherComponent},
  {path:"terminal/:jwt/all",component:GuacamoleMultiTerminalComponent},
  {path:'popout',component:PopoutComponent},
  {path:"layouteditor",component:LayoutEditorComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routerinstructor)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
