import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { GeneralModule } from './../../../usorta-library/src/lib/modules/general/general.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, SecurityContext } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateStore } from '@ngx-translate/core';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';
import { ServiceInterceptor } from 'projects/usorta-library/src/interceptors/service.interceptor';
import { FooterTestService } from 'projects/usorta-library/src/lib/services/footer/footer-test.service';
import { FooterService } from 'projects/usorta-library/src/lib/services/footer/footer.service';
import { LoginService } from 'projects/usorta-library/src/lib/services/login/login.service';
import { TestLoginService } from 'projects/usorta-library/src/lib/services/login/test-login.service';
import { TenantTestService } from 'projects/usorta-library/src/lib/services/tenant/tenant-test.service';
import { TenantService } from 'projects/usorta-library/src/lib/services/tenant/tenant.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { InstructorCourseItemComponent } from './components/instructor-course-item/instructor-course-item.component';
import { InstructorCoursesListComponent } from './components/instructor-courses-list/instructor-courses-list.component';
import { MessagesSummaryComponent } from './components/messages-summary/messages-summary.component';
import { MessageItemComponent } from './components/message-item/message-item.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomPageComponent } from './components/custom-page/custom-page.component';
import { CourseLobbyComponent } from './components/course-lobby/course-lobby.component';
import { LearnSessionComponent } from './components/learn-session/learn-session.component';
import { WhiteboardModule } from '@usorta-packages/whiteboard';
import { ChatLibraryModule } from '@usorta-packages/chat-library';
import { CourseDocumentsComponent } from './components/learn-session/course-documents/course-documents.component';
import { LearnMemberComponent } from './components/learn-session/learn-member/learn-member.component';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { GuacamoleTerminalComponent } from './components/guacamole-terminal/guacamole-terminal.component';
import { GuacamoleMultiTerminalComponent } from './components/guacamole-multi-terminal/guacamole-multi-terminal.component';
import { SessionEndedComponent } from './components/learn-session/session-ended/session-ended.component';
import { LayoutEditorComponent } from './components/layout-editor/layout-editor.component';
import { GlobalOkCancelEventService } from 'projects/usorta-library/src/lib/services/globalOkCancelEvent/global-ok-cancel-event.service';
import { RefreshTokenService } from 'projects/usorta-library/src/lib/services/auth/refresh-token.service';
import { InstructorLmsClientRefreshTokenService } from './services/instructor-lms-client-refresh-token.service';
/*export function LanguageLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.tenantUrl+"language?lang=", "");
}*/
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    NavigationBarComponent,
    InstructorCourseItemComponent,
    InstructorCoursesListComponent,
    MessagesSummaryComponent,
    MessageItemComponent,
    CalendarComponent,
    CustomPageComponent,
    CourseLobbyComponent,
    LearnSessionComponent,
    CourseDocumentsComponent,
    LearnMemberComponent,
    GuacamoleTerminalComponent,
    GuacamoleMultiTerminalComponent,
    SessionEndedComponent,
    LayoutEditorComponent,
  ],
  imports: [
    BrowserModule,
    GeneralModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    WhiteboardModule,
    ChatLibraryModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false
        }
      }
    })
  ],
  providers: [
    TranslateStore,
    {provide:LoginService,useClass:TestLoginService},
    {provide:RefreshTokenService,useClass:InstructorLmsClientRefreshTokenService},
    {provide:HTTP_INTERCEPTORS,useClass:ServiceInterceptor,multi:true},
    {provide:FooterService,useClass:FooterTestService},
    {provide:TenantService,useClass:TenantTestService},
    GlobalOkCancelEventService,
    GeneralModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
