import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GuacAuthService, GuacConnectionInfo, TunnelStates } from '@usorta-packages/guac-library';
import { ToastrService } from 'ngx-toastr';
import { TerminalMessageModel } from 'projects/usorta-library/src/lib/models/RemoteClientModels/terminalMessageModel';
import { TerminalUserModel } from 'projects/usorta-library/src/lib/models/RemoteClientModels/terminalUserModel';
import { SessionSocketService } from 'projects/usorta-library/src/lib/services/session/session-socket.service';
import { TerminalChatService } from 'projects/usorta-library/src/lib/services/terminalChat/terminal-chat.service';
declare var $:any;
@Component({
  selector: 'app-guacamole-terminal',
  templateUrl: './guacamole-terminal.component.html',
  styleUrls: ['./guacamole-terminal.component.css']
})

export class GuacamoleTerminalComponent implements OnInit {

  constructor(private terminalChatService:TerminalChatService,private toastrService:ToastrService, private guacAuthService:GuacAuthService,private activatedRoute:ActivatedRoute,private sessionSocket:SessionSocketService) {
    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      event.returnValue = "Sayfa kapatılacak";
      return localStorage.removeItem(this.connectKey);

   });
   }
  guacToken = "";
  jwt:string;
  connectKey:string = "";
  vmName:string;
  start:boolean = false;
  keyboardAccess:boolean[] = [true];
  mouseAccess:boolean[] = [true];
  socketConnected:boolean = false;
  connectJson:any;
  sharedConnection:boolean = false;
  privateMessages:TerminalMessageModel[] = [];
  state:number = 1;
  loaderMessage:string = "Bağlantı Başlatılıyor";
  terminalUser:TerminalUserModel[] = [];
  logoUrl:string = "";

  ngOnInit(): void {
    this.getLogo();
    this.activatedRoute.params.subscribe(param=>{
      this.jwt = param["jwt"];
      this.vmName = param["vm"];
      this.connectKey = param["key"] ?? "";
      localStorage.setItem("GUAC_AUTH",'{"authToken":""}');
      setTimeout(()=>{
        this.start = true;
      },100);
    })
    let guacConnectionInfo:GuacConnectionInfo = {url:`//${window.location.hostname}`,wsUrl:`wss://${window.location.hostname}`,signalRSocket:"/hubs/session",mainPath:"/guacamole/"};
    this.guacAuthService.setGuacamoleParams(guacConnectionInfo);
    this.parseConnectKey();

    this.terminalUser.push({userId:this.connectJson['userId'],displayName:this.connectJson['displayName']});
    this.terminalUser = [...this.terminalUser]
    this.startSessionSocket();
    this.writeTextarea();
  }
  /*
  Updates
  */
  writeTextarea(){
    $(document).on('keydown',(e:KeyboardEvent)=>{
      if($(e.target).hasClass('privateMessageTextarea')){
        e.preventDefault();
        e.stopPropagation();
        var eventKey = new KeyboardEvent('keydown',{code:e.code,key:e.key,keyCode:e.keyCode});
          var textarea:HTMLTextAreaElement = e.target as HTMLTextAreaElement;
          if(eventKey.key.length == 1){
            textarea.value += e.key;
          }else if(eventKey.key == "Backspace"){
            textarea.value = textarea.value.substring(0,textarea.value.length-1);
          }else if(eventKey.key == "Enter"){
            textarea.value += "\n";
          }else{
            console.log(eventKey.key);
          }
      }
    })
  }
  getLogo(){
    var tenantInfo = JSON.parse(localStorage.getItem("tenantInfo") ?? "");
    var tenantJson = tenantInfo['applicationConfiguration'];
    this.logoUrl = tenantJson['logo'];
  }
  openChat() {
    this.keyboardAccess = [false];
    this.mouseAccess = [false];
  }
  closeChat() {
    this.keyboardAccess = [true];
    this.mouseAccess = [true];
  }
  startTerminalChat() {
    let parseConnectKey = this.connectJson;
    let chatApiUrl = parseConnectKey['chatApi'];
    let chatRoom = parseConnectKey['chatRoom'];
    let chatWS = parseConnectKey['chatSocket'];
    this.terminalChatService.initializeTerminalChat(
      chatWS,
      chatRoom,
      chatApiUrl
    );
  }
  startTerminalMessageListener() {
    this.terminalChatService.receivePrivateMessages.subscribe({
      next: (data) => {
        this.toastrService.info(data.message);
        let terminalMessageModel:TerminalMessageModel = {your:false,userId:data.senderId,message:data.message};
        this.privateMessages.push(terminalMessageModel)
        this.privateMessages = [...this.privateMessages];
      },
    });
  }
  sendTerminalMessage(tm:TerminalMessageModel) {
    this.terminalChatService.sendMessageToUser(tm.userId, tm.message).then(x=>{
      console.log("Result : "+ x);
      if(x == true){
        tm.your = true;
        this.privateMessages.push(tm);
        this.privateMessages = [...this.privateMessages];
      }
    });
  }
  changeTunnelState(state:number){
    console.log("Tunnel state : "+state)
    let tunnelState = state as TunnelStates;
    if(tunnelState == TunnelStates.Closed){
      this.changeConnectingMessage(8);
    }
  }
  changeConnectingMessage(state: number) {
    console.log('state changed ' + state);
    this.state = state;
    switch (state) {
      case -1:
        this.loaderMessage = 'Bağlantı Değiştiriliyor';
        break;
      case 1:
        this.loaderMessage = 'Bağlantı Başlatılıyor';
        break;
      case 2:
        this.loaderMessage = 'Görüntü Bekleniyor';
        break;
      case 3:
        this.loaderMessage = 'Bağlantı Başarılı';
        break;
      case 4:
        this.loaderMessage = 'Bağlantı kapatılıyor';
        break;
      case 5:
        this.loaderMessage = 'Bağlantı Koptu';
        break;
      case 6:
        this.loaderMessage = 'Oturum servisi ile bağlantı kurulamadı';
        break;
      case 7:
        this.loaderMessage = 'Lütfen makineye oturum sayfası üzerinden giriş yapın';
        break;
      case 8:
        this.loaderMessage = 'Tünel bağlantısı koptu';
        break;
    }
  }
  //-------------------------------
  parseConnectKey(){
    this.connectJson = JSON.parse(localStorage.getItem(this.connectKey) ?? "");
    this.sharedConnection = this.connectJson['sharedConnection'];
  }
  startSessionSocket(){

    let websocketUrl = this.connectJson['sessionSocket'];
    let sessionId = this.connectJson['sessionId'];
    this.sessionSocket.ConnectSocket(websocketUrl,sessionId).then(connected=>{
      this.socketConnected = connected;
      if(this.sharedConnection){
        this.startTerminalChat();
        this.startTerminalMessageListener();
      }

    })
  }
  clientStateChanged(state:number){
    console.log("Client State");
    this.changeConnectingMessage(state);
    //States
    /*
    STATE_IDLE = 0,
    STATE_CONNECTING = 1,
    STATE_WAITING = 2,
    STATE_CONNECTED = 3,
    STATE_DISCONNECTING = 4,
    STATE_DISCONNECTED = 5
    */

    console.log(state)

  }
  changeTerminalAccess(access:boolean){
    let userId = this.connectJson['userId'];
    this.sessionSocket.changeTerminalAccess(userId,this.vmName,access);
  }
  clientAccess(access:boolean){
    this.keyboardAccess = [access]
    this.mouseAccess = [access];
  }

}
