import { TenantService } from 'projects/usorta-library/src/lib/services/tenant/tenant.service';
import { TenantListenerService } from './../../../usorta-library/src/lib/services/listener/tenant-listener.service';
import { TranslateService } from '@ngx-translate/core';
import { TenantInfoModel } from './../../../usorta-library/src/lib/models/TenantModels/tenantInfoModel';
import { DesignModel } from 'projects/usorta-library/src/lib/models/TenantModels/designModel';
import { LocalesModel } from './../../../usorta-library/src/lib/models/TenantModels/localesModel';
import { Component, NgModule,AfterViewInit, ViewContainerRef, ViewChild, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FooterListenerService } from 'projects/usorta-library/src/lib/services/listener/footer-listener.service';
import { LoginService } from 'projects/usorta-library/src/lib/services/login/login.service';
import { LoggedUserListenerService } from 'projects/usorta-library/src/lib/services/listener/logged-user-listener.service';
import { CookiePreferencesService } from 'projects/usorta-library/src/lib/services/cookiePreferences/cookie-preferences.service';
import { CookiePreferenceListenerService } from 'projects/usorta-library/src/lib/services/listener/cookie-preference-listener.service';
import { GlobalModalService } from 'projects/usorta-library/src/lib/services/globalModal/global-modal.service';
import { GlobalOkCancelEventService } from 'projects/usorta-library/src/lib/services/globalOkCancelEvent/global-ok-cancel-event.service';
import { FormatStringService } from 'projects/usorta-library/src/lib/services/localizedFormatString/format-string.service';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { MenuItems } from 'projects/usorta-library/src/lib/models/menu/menuItems';
import { NavigationService } from 'projects/usorta-library/src/lib/services/navigation/navigation.service';
declare var window:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild("modalRef") modalRef:ElementRef;
  title = 'instructor';
  isLogged:boolean = false;
  showTenantLoader: boolean = true;
  subscribe: any;
  localesModel: LocalesModel;
  designModel: DesignModel;
  showFooter: boolean = true;
  footerSubscribe:any;
  tenantInfoModel:TenantInfoModel;
  loginSubscribe:any;
  footerHideUrl: string[] = ['/register', '/forgot-password', '/reset-password','/popout'];
  footerMenuHideUrl:string[] = [];
  footerMenuSubscribe:any;
  selectedLanguage:string | null= null;
  cookiePreferenceSubsribe:any;
  cookiePreferenceListenerSubscribe:any;
  showCookiePreferencesBox:boolean = false;
  showNavi = true;
  menuItems:MenuItems[];
  constructor(
    private viewContainerRef:ViewContainerRef,
    private globalModalService:GlobalModalService,
    public globalOkCancelService:GlobalOkCancelEventService,
    private translateService: TranslateService,
    private tenantListenerService: TenantListenerService,
    private tenantService: TenantService,
    private router:Router,
    private footerListener:FooterListenerService,
    private loginService:LoginService,
    private loggedUserListener:LoggedUserListenerService,
    private cookiePreferenceService:CookiePreferencesService,
    private cookiePreferencesListenerService:CookiePreferenceListenerService,
    private formatStringService:FormatStringService,
    private navigationService:NavigationService
  ) {
    this.tenantListenerService.tenantStatus$.subscribe((response) => {
      if (response == 'true') {
        var tenantInfo = localStorage.getItem('tenantInfo');
        if (tenantInfo) {
          this.localesModel = JSON.parse(tenantInfo).locales;
          this.designModel = JSON.parse(tenantInfo).design;
          this.tenantInfoModel = JSON.parse(tenantInfo).applicationConfiguration;
          document.title = this.tenantInfoModel.siteTitle;
        }
        this.getLangs().then(x=>{
          formatStringService.getFormatStrings(environment.applicationId,translateService.currentLang).subscribe({
            next:(response)=>{
              console.log(response);
              var content = response.content;
              var keys = Object.keys(content);
              keys.forEach(key=>{
                console.log(content[key]);
                window[key] = content[key];
              })
              this.getMenuItems();
            }
          });
        });
        this.getDesign();
    }
  })
}
ngAfterViewInit(): void {
  this.globalModalService.globalModalRef = this.viewContainerRef;
  this.globalModalService.globalElementRef = this.modalRef;
  this.globalOkCancelService.globalOkCancelRef = this.viewContainerRef;
  this.globalOkCancelService.globalOkCancelElementRef = this.modalRef;
}
  ngOnInit() {
    this.subscribeToTenantLoader();
    this.showFooterListener();
    this.router.events.subscribe((evt)=>{
      if(evt instanceof NavigationEnd){
        if(evt.url.includes("popout") || evt.url.includes("session/") || evt.url.includes("remoteClient/") ||evt.url.includes("terminal/")){
          this.showNavi = false;
        }else{
          this.showNavi = true;
        }
        if(this.footerHideUrl.indexOf(evt.url)>-1){
          this.hideFooterFunction();
        }else{
          this.showFooterFunction();
        }
        if(this.footerMenuHideUrl.indexOf(evt.url)>-1){
          this.hideFooterMenuFunction();
        }else{
          this.showFooterMenuFunction();
        }
      }
    })

  }
  getMenuItems(){
    var appId = environment.applicationId;
    var tenantId = environment.tenantId;
    this.navigationService.getMenuByName("default",appId,tenantId).subscribe({
      next:(response)=>{
        this.menuItems = response.content.subItems;
      }
    })
  }
  subscribeToTenantLoader() {
    this.tenantListenerService.tenantStatus$.subscribe((response) => {
      if (response == 'true') {
        this.showTenantLoader = false;
        this.listenCookiePreferencesSaved();
        this.cookiePreferencesControl();
        this.loggedUserSubscribe();
      }
    });
  }
  getDesign(){
    if (this.designModel) {
      setTimeout(() => {
        this.tenantService.getCSS().subscribe((response) => {
          let style = document.createElement('style');
          style.id = 'customStyle';
          style.innerHTML = response;
          document.head.appendChild(style);
        });
      }, 100);
    }
  }
  selectedLanguageChecker(){
    this.selectedLanguage = localStorage.getItem("selectedLanguage");
    if(this.selectedLanguage != null){
      return true;
    }else{
      return false;
    }
  }
  getLangs(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let useBrowserLanguage: boolean = false;
      let langs = this.localesModel.allowedLocales;
      console.log(langs);
      var allowedLanguageList:string[] = [];
      langs.forEach(lang=>{
        allowedLanguageList.push(lang.value);
      })
      console.log(allowedLanguageList);
      this.translateService.addLangs(allowedLanguageList);
      let browserLang = this.translateService.getBrowserLang();
      if (this.selectedLanguageChecker()) {
        browserLang = this.selectedLanguage ? this.selectedLanguage : browserLang;
      }
      console.log(navigator.languages);
      langs.forEach((lang) => {
        var languageVal = lang.value;
        if (browserLang && languageVal.includes(browserLang)) {
          useBrowserLanguage = true;
          this.translateService.use(languageVal).subscribe({
            next: () => {
              resolve(true);
            }
          });

        }
      });
      if (useBrowserLanguage == false) {
        this.translateService.use(this.localesModel.defaultLocale).subscribe({
          next: () => {
            resolve(true);
          }
        });
      }
    })

  }
  showFooterListener(){
    this.footerListener.footerStatus$.subscribe((response) => {
      if (response == 'hide') {
        this.showFooter = false;
      }else{
        this.showFooter = true;
      }
    });
  }
  showFooterFunction(){
    if(this.footerSubscribe){
      this.footerSubscribe.unsubscribe();
    }
    this.footerSubscribe = this.footerListener.getFooterStatus('show').subscribe();
  }
  hideFooterFunction(){
    if(this.footerSubscribe){
      this.footerSubscribe.unsubscribe();
    }
    this.footerSubscribe = this.footerListener.getFooterStatus('hide').subscribe();
  }
  showFooterMenuFunction(){
    if(this.footerMenuSubscribe){
      this.footerMenuSubscribe.unsubscribe();
    }
    this.footerMenuSubscribe = this.footerListener.getFooterMenuStatus('true').subscribe();
  }
  hideFooterMenuFunction(){
    if(this.footerMenuSubscribe){
      this.footerMenuSubscribe.unsubscribe();
    }
    this.footerMenuSubscribe = this.footerListener.getFooterMenuStatus('false').subscribe();
  }
  cookiePreferencesControl(){
    this.cookiePreferenceService.cookiePreferencesSaved().subscribe((response) => {
        this.setCookieListener(response);
    });
  }
  setCookieListener(state:boolean){
    if(this.cookiePreferenceSubsribe){
      this.cookiePreferenceSubsribe.unsubscribe();
    }
    this.cookiePreferenceSubsribe = this.cookiePreferencesListenerService.setCookiePreferences(state).subscribe();
  }
  listenCookiePreferencesSaved(){
    this.cookiePreferenceListenerSubscribe = this.cookiePreferencesListenerService.cookiePreferences$.subscribe((response) => {
      if (response == true) {
        this.showCookiePreferencesBox = false;
      }else{
        this.showCookiePreferencesBox = true;
      }
    });
  }
  loggedUserSubscribe(){
    this.loginSubscribe = this.loggedUserListener.loggedUserStatus$.subscribe(response=>{
      console.log(response);
      if(response == 'false'){
        this.isLogged = false;
        this.loggedControlWithExpiration();
      }else{
        this.isLogged = true;
      }
    }
    );
  }
  loggedControlWithExpiration(){
    this.isLogged = this.loginService.isLoggedControlWithExpiration();
  }
}
