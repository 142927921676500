import { Observable } from 'rxjs';
import { ApiUrlService } from './../../../../usorta-library/src/lib/services/api/api-url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageBox } from '../models/messageBox';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  getMessageSummary():Observable<MessageBox>{
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<MessageBox>(apiUrl + 'instructor/messages/summary');
  }
}
