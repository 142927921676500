import { GuacamoleSharedConnectionDto } from './../../../../../usorta-library/src/lib/models/RemoteClientModels/guacamoleSharedConnectionDto';
import { filter, merge, share } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { SessionSocketService } from 'projects/usorta-library/src/lib/services/session/session-socket.service';
import { LearnMainModel } from '../../models/learnSession/LearnMainModel';
import { LearnToolsEnum } from '../../models/learnSession/learnToolsEnum';
import { InstructorLearnService } from '../../services/instructor-learn.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateNoteModel } from '../../models/learnSession/Notes/updateNoteModel';
import { CreateNoteModel } from '../../models/learnSession/Notes/createNoteModel';
import { StudentNoteModel } from '../../models/learnSession/StudentNoteModel';
import { GuacamoleService } from 'projects/usorta-library/src/lib/services/guacamole/guacamole.service';
import { RemoteClientSessionModel } from 'projects/usorta-library/src/lib/models/RemoteClientModels/remoteClientSessionModel';
import * as SignalR from '@microsoft/signalr'
import { GuacAuthService, GuacConnectionInfo } from '@usorta-packages/guac-library';
import { GlobalModalService } from 'projects/usorta-library/src/lib/services/globalModal/global-modal.service';
import { ModalTypes } from 'projects/usorta-library/src/lib/models/ModalManagementModels/modalTypeEnum';
declare var $: any;
@Component({
  selector: 'app-learn-session',
  templateUrl: './learn-session.component.html',
  styleUrls: ['./learn-session.component.css']
})
export class LearnSessionComponent implements OnInit {
  fullscreen:boolean = false;
  constructor(private globalModalService:GlobalModalService,private guacamoleService:GuacamoleService,private guacAuthService:GuacAuthService, private sessionSocket:SessionSocketService, private learnService:InstructorLearnService,private toastrService:ToastrService,private activatedRoute:ActivatedRoute,private router:Router) {
    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      event.returnValue = "Sayfa kapatılacak";
      return event;

   });

   }
  helpRequests:{displayName:string,userId:string,terminalId:string}[] = [];
  learnContent:LearnMainModel
  selectedLearnTool:LearnToolsEnum = LearnToolsEnum.Content;
  editMode:boolean = false;
  noteEditId?:string = undefined;
  courseId:string = "";
  socketConnected:boolean = false;
  socketConnectedError:boolean = false;
  sessionId:string = "";
  isHandRaised:boolean = false;
  socketConnectionId = "";
  lastPoll:any = null;
  currentMembers:Map<string,string> = new Map();
  sharedConnections:GuacamoleSharedConnectionDto[] = [];
  guacMachines:string[] = [];
  guacToken:string = "";
  sessionHub:SignalR.HubConnection;
  liveStreams:{user:string,stream:MediaStream}[] = [];
  selectedLiveStream:string = "";
  raiseHandList:string[] = [];
  userId:string = "";
  mergedPolls:any[] = [];
  sessionStatus:number = -1;
  streamContentEl:HTMLDivElement;
  mainPageWindow:Window = window;
  chatWindow:Window[] = [window];
  noteWindow:Window = window;
  courseContentWindow:Window = window;
  whiteboardWindow:Window = window;
  fileAttachmentWindow:Window = window;
  streamWindow:Window = window;
  guacamoleScreensWindow:Window = window;
  newMessageReceived:boolean = false;
  userIdleStatus:boolean = false;
  lastActionTimestamp:Date = new Date();
  loadingMultiscreen:boolean = false;
  guacMultiscreenDisconnect = [false];
  tabGuacamoleJwt:string | undefined;
  guacamoleVmConnections:any[];
  ngOnInit(): void {

    this.activatedRoute.params.subscribe(param=>{
      this.getLearnContent(param['session']);
      this.courseId = param['session'];
      this.fullscreenListener();

      
    })

  }
  startSocket = () => {
    let websocketUrl = this.learnContent.courseInfo.features.session.websocketUrl;
    let sessionId = this.learnContent.courseInfo.features.session.sessionId;
    this.sessionId = sessionId;
    this.socketConnected = false;
    this.socketConnectedError = false;
    this.sessionSocket.ConnectSocket(websocketUrl,sessionId,true).then(connected=>{
      this.socketConnected = connected;
      if(connected == false){
        this.socketConnectedError = true;
      }
      if(this.socketConnected == true){
        this.sessionHub = this.sessionSocket.getHubConnection() as unknown as SignalR.HubConnection;
        this.raiseHandListener();
        this.pollListener();
        this.voteListener();
        this.sessionConnectionListener();
        this.memberJoinLeftListener();
        this.helpRequestListener();
        this.otherTabJoinListener();
        this.userIdListener();
        this.sessionStatusListener();
        this.getSessionStatus();
        this.userStatusChangeListener();
        //this.createGuacamoleMultiscreenKey();
        this.sessionSocket.startPingSender();
        let streamContent = document.getElementsByClassName("content_5")[0] as HTMLDivElement;
        this.streamContentEl = streamContent;
        this.miniVideoClickListener();
      }

    },rejected=>{

    })
  }
  showAllPolls(){
    let localKeys = Object.keys(localStorage);
    let idList:string[] = [];
    this.mergedPolls = [];
    localKeys = localKeys.filter(x=>x.startsWith("poll_"+this.sessionId));
    localKeys.forEach((x)=>{
      x = x.replace("poll_"+this.sessionId+"_","");
      idList.push(x);
    })
    let pollList:any[] = JSON.parse(localStorage.getItem("polls_"+this.sessionId) ?? "[]");
    idList.forEach((pollId)=>{

      let poll = pollList.find(x=>x.pollId == pollId);
      let answers:any[] = JSON.parse(localStorage.getItem("poll_"+this.sessionId+"_"+pollId) ?? "[]");
      let item:{poll:any,answers:any[]} = {answers:answers,poll:poll};
      this.mergedPolls.push(item);
    })

    console.log(this.mergedPolls);

  }
  getSessionStatus(){
    this.sessionSocket.getHubConnection().invoke("GetSessionStatus");
  }
  userStatusChangeListener(){
    this.sessionSocket.userStatusChangeListener.subscribe({
      next:(data)=>{
        console.log("status geldi");
        console.log(data);
        let className = "offline";
        switch(data.status){
          case 0:
            className = "offline";
            break;
          case 1:
            className = "active";
            break;
          case 2:
            className = "away";
            break;
          case 3:
            className = "idle";
            break;
          case 4:
            className = "dnd";
            break;
        }
        this.statusChanger(data.userId,className);
        

      }
    })
  }
  statusChanger(userId:string,className:string){
    try{
      let statusNames = ["offline","active","away","idle","dnd"];
      let statusDiv = document.getElementById("status_"+userId) as HTMLDivElement;
      statusDiv.classList.remove(...statusNames);
      statusDiv.classList.add(className);
    }catch{
  
    }
  }

  /*
 User Status Start 
  */

 userIdleListener = () => {
  window.document.addEventListener("mousemove",()=>{
    this.lastActionTimestamp = new Date();
    if(this.userIdleStatus){
      this.sessionSocket.setUserState(this.sessionId,1);
      this.userIdleStatus = false;
    }
  })
}
userIdleControl(){
  setTimeout(()=> {
    let temp = this.lastActionTimestamp.getMinutes() + 1;
    if(temp < new Date().getMinutes()){
      this.sessionSocket.setUserState(this.sessionId,3);
      this.userIdleStatus = true;
    }else {
      this.sessionSocket.setUserState(this.sessionId,1);
      this.userIdleStatus = false;
    }
    this.userIdleControl();
  },10000);
}


 /*
 User Status End
 */
  showCurrentPollVote(userId:string){
    var pollId = this.lastPoll.pollId;
    var votes = JSON.parse(localStorage.getItem("poll_"+this.sessionId+"_"+pollId) ?? "[]");
    var currentVote = votes.find((x:any)=>x.userId == userId);

    if(currentVote){
      if(currentVote.vote){
        return "T";
      }else{
        return "F";
      }
    }else{
      return "";
    }

  }
  sessionStatusListener(){
    this.sessionSocket.sessionStatusListener.subscribe(response=>{
      this.sessionStatus = response;
    })
  }
  saveVote(pollId:string,userId:string,displayName:string,vote:boolean){
    var name = "poll_"+this.sessionId+'_'+pollId;
    let data = [];
    if(localStorage.getItem(name)){
      data = JSON.parse(localStorage.getItem(name) ?? "[]");
    }
    let userData:{userId:string,displayName:string,vote:boolean} = {"userId":userId,"displayName":displayName,"vote":vote};
    data = data.filter((x:any)=>x.userId != userId);
    data.push(userData);
    localStorage.setItem(name,JSON.stringify(data));
  }
  raiseHand(){
    this.sessionSocket.sendRaiseHand(this.isHandRaised ? false : true);
  }
  pollListener(){
    this.sessionSocket.pollListener.subscribe((poll)=>{
      this.lastPoll = poll;
      this.pollSaveStorage();
    })
  }
  pollSaveStorage(){
    console.log(this.lastPoll);
    var pollText = localStorage.getItem("polls_"+this.sessionId) ?? "[]";
    var pollJson = JSON.parse(pollText);
    let pollQuestion = `{"pollId":"${this.lastPoll.pollId}","pollQuestion":"${this.lastPoll.pollQuestion}"}`;

    pollJson.push(JSON.parse(pollQuestion));
    localStorage.setItem("polls_"+this.sessionId,JSON.stringify(pollJson));

  }
  userIdListener(){
    this.sessionSocket.userIdListener.subscribe((data:string)=>{
      this.userId = data;

    })
    this.sessionSocket.getUserId();
  }
  otherTabJoinListener(){
    this.sessionSocket.otherJoinInfo.subscribe((response)=>{
      if(response.connectionId !== this.sessionSocket.getConnectionId()){
        this.toastrService.error("Başka bir yerden giriş yapıldı");
        this.sessionSocket.disconnect();
        this.sessionSocket.getHubConnection().stop();
        this.router.navigate(["/sessionkicked/"+this.sessionId]);
      }
    })
  }
  memberJoinLeftListener(){

    this.sessionSocket.memberJoinListener.subscribe((joinedMember)=>{
      this.currentMembers.set(joinedMember.memberId,joinedMember.userId);
      this.joinMember(joinedMember);
    })
    this.sessionSocket.memberLeftListener.subscribe((leftMemberModel)=>{
      this.leftMember(leftMemberModel);
    })
  }
  createPoll(){
    let textarea = document.getElementById("createPollTextarea") as HTMLTextAreaElement;
    var val = textarea.value;
    if(val == "" ||val == undefined){
      this.toastrService.error("Soru boş olamaz");
    }else{
      this.sessionSocket.createPoll(val);
    }

  }
  joinMember(joinedMember:any){
    try{


    let statusDiv = document.getElementById("status_"+joinedMember.userId) as HTMLDivElement;
    statusDiv.classList.add("active");
  }catch{

  }
  }
  leftMember(leftMember:any){
    try {


    let userId = this.currentMembers.get(leftMember.userId);
    let statusDiv = document.getElementById("status_"+userId) as HTMLDivElement;
    statusDiv.classList.remove("active");
  }catch{

  }
  }
  voteYes(){
    if(this.lastPoll == null){
      this.toastrService.error("Oy verilebilecek bir anket yok");
      return;
    }
    this.sessionSocket.miniSurveyVoteYes(this.lastPoll.pollId);
    this.toastrService.info("Ankete evet oyu verdiniz");
    this.lastPoll = null;
  }
  voteNo(){
    if(this.lastPoll == null){
      this.toastrService.error("Oy verilebilecek bir anket yok");
      return;
    }
    this.sessionSocket.miniSurveyVoteNo(this.lastPoll.pollId);
    this.toastrService.info("Ankete hayır oyu verdiniz");
    this.lastPoll = null;
  }
  voteListener(){
    this.sessionSocket.voteListener.subscribe((data)=>{
      if(data.userId != this.socketConnectionId){
        this.toastrService.info(data.displayName + " ankete" +(data.pollAnswer ? " evet " : " hayır ") + "oyu verdi");
        this.saveVote(data.pollId,data.userId,data.displayName,data.pollAnswer);
      }
    })
  }
  raiseHandListener(){
    this.sessionSocket.raiseHandListener.subscribe((data)=>{
      if(data.userId == this.userId){
        this.isHandRaised  = this.isHandRaised ? false : true;
      }else{
        if(data.raise){
          this.raiseHandList.push(data.userId);
        }else{
          this.raiseHandList = this.raiseHandList.filter(x=>x != data.userId);
        }

        this.toastrService.info(data.displayName + " " +(data.raise ? "el kaldırdı" : "elini indirdi"));
      }

    })
  }
  raiseHandListControl(userId:string){
   var arr = this.raiseHandList.filter(x=>x == userId);
   if(arr.length > 0){
    return true;
   }else{
    return false;
   }
  }

  sessionConnectionListener(){
    this.sessionSocket.sessionConnectionListener.subscribe(response=>{
      this.socketConnectionId = response.memberId;
    })
  }
  getLearnContent(courseId:string){
    this.learnService.getLearn(courseId).subscribe({
      next:(response)=>{
        this.learnContent = response;
        this.startSocket();
        this.guacamoleStart();
      },
      error:(err)=>{
        this.toastrService.error("Eğitim içeriği alınırken bir sorun oluştu");
      }
    })
  }
  miniVideoClickListener(){
    this.streamContentEl.addEventListener('click',(event)=>{
      let btn = event.target;
      if($(btn).hasClass("selectMiniVideo")){
        var userid = $(btn).attr("data-userid");
        this.setBigLiveStream(userid);
      }
    })
  }
  addMiniLiveStream(userId:string){
    let miniStreamDiv = $(this.streamContentEl).find('#miniStreamList').get(0) as HTMLVideoElement
    var data = this.liveStreams.find(x=>x.user == userId);
    if(data?.stream != undefined){
      var miniVideoEl = document.createElement('video');
      miniVideoEl.id = "miniVideo_"+userId;
      miniVideoEl.classList.add("selectMiniVideo");
      miniVideoEl.autoplay = true;
      miniVideoEl.controls = false;
      miniVideoEl.muted = true;
      miniVideoEl.width = 150;
      miniVideoEl.height = 150;
      miniVideoEl.style.backgroundColor = "rgb(165 165 165)"
      miniVideoEl.style.width = "125px";
      miniVideoEl.style.height = "125px";
      miniVideoEl.style.display = "inline-block";
      miniVideoEl.style.border = "1px solid silver";
      miniVideoEl.style.borderRadius = "20px"
      miniVideoEl.style.margin = "10px";
      miniVideoEl.style.cursor = "pointer";
      miniVideoEl.srcObject = data?.stream;
      $(miniVideoEl).attr("data-userid",userId);
      miniStreamDiv.appendChild(miniVideoEl);
    }

  }
  removeMiniLiveStream(userId:string){
    $(this.streamContentEl).find("#miniVideo_"+userId).remove();
  }
  setBigLiveStream(userId:string){
    var data = this.liveStreams.find(x=>x.user == userId);
    if(data?.stream != undefined){
      var vid = $(this.streamContentEl).find("#liveVideoStream").get(0) as HTMLVideoElement;
      vid.srcObject = data?.stream;
      this.selectedLiveStream = userId;
    }
  }
  getLiveStream(event:{user:string,stream:MediaStream}){
    this.removeMiniLiveStream(event.user);
    this.liveStreams = this.liveStreams.filter(x=>x.user != event.user);
    this.liveStreams.push(event);
    this.addMiniLiveStream(event.user);
    this.setBigLiveStream(event.user);
  }
  handleClosedStream(userId:string){
    this.liveStreams = this.liveStreams.filter(x=>x.user != userId);
    if(this.selectedLiveStream == userId){
      if(this.liveStreams.length > 0){
        this.setBigLiveStream(this.liveStreams[0].user);
      }else{
        this.selectedLiveStream = "";
      }
    }
    this.removeMiniLiveStream(userId);
  }
  popOut(selectedLearnTool:number){
    let changeWindow:boolean = false;
    let pageStyles = document.getElementsByTagName("style");
    
    let win = window.open("/popout", "", "width=900, height=800") ?? new Window(); //about:blank sayfası açarsak scriptler ve stiller yüklenmiyor. o yüzden /popout adında boş bir sayfa oluşturdum. İçerikleri buraya ekleyeceğim
    
    let cnt = selectedLearnTool == 5 ? this.streamContentEl :document.getElementsByClassName("content_"+selectedLearnTool)[0];
    win.addEventListener("load",()=>{
      setTimeout(()=>{
        if(selectedLearnTool == 0){
          this.courseContentWindow = win;
        }
        if(selectedLearnTool == 1){
          this.whiteboardWindow = win;
        }
        if(selectedLearnTool == 2){
          this.chatWindow = [win];
        }
        if(selectedLearnTool == 3){
          this.noteWindow = win;
        }
        if(selectedLearnTool == 4){
          this.fileAttachmentWindow = win;
        }
        if(selectedLearnTool == 5){
          this.streamWindow = win;
        }
        if(selectedLearnTool == 5){
          this.guacamoleScreensWindow = win;
        }
        win.document.body.append(cnt);
        for (let index = 0; index < pageStyles.length; index++) {
          const sty = pageStyles[index];
          win.document.head.append(sty.cloneNode(true))
          
        }
      },500)
    })
    win.addEventListener("unload",()=>{
      document.getElementsByClassName("contentDiv_"+selectedLearnTool)[0].append(cnt)
      if(changeWindow && selectedLearnTool == 0){
        this.courseContentWindow = window;
      }
      if(changeWindow && selectedLearnTool == 1){
        this.whiteboardWindow = window;
      }
      if(changeWindow && selectedLearnTool == 2){
        this.chatWindow = [window];
      }
      if(changeWindow && selectedLearnTool == 3){
        this.noteWindow = window;
      }
      if(changeWindow && selectedLearnTool == 4){
        this.fileAttachmentWindow = window;
      }
      if(changeWindow && selectedLearnTool == 5){
        this.streamWindow = window;
      }
      changeWindow = true;
      
    })
  }
  messageToast(ev:any){
    console.log(ev);
    if(sessionStorage.getItem("username") == ev.data.name){
      return;
    }
    if(this.selectedLearnTool != LearnToolsEnum.TextChat){
      this.newMessageReceived = true;
    }

    switch(ev.type){
      case "message":
        console.log(ev.data);
        break;
      case "privateMessage":
        console.log(ev.data);
        break;   
    }
  }
  switchLearnTool(learnTools:LearnToolsEnum){
    if(this.selectedLearnTool == LearnToolsEnum.VirtualMachineView && learnTools != LearnToolsEnum.VirtualMachineView){
      this.guacMultiscreenDisconnect = [true];
    }
    switch(learnTools){
      case LearnToolsEnum.Content :
        this.selectedLearnTool = LearnToolsEnum.Content;
        break;
      case LearnToolsEnum.Whiteboard :
        this.selectedLearnTool = LearnToolsEnum.Whiteboard;
        break;
      case LearnToolsEnum.TextChat :
        this.selectedLearnTool = LearnToolsEnum.TextChat;
        this.newMessageReceived = false;
        break;
      case LearnToolsEnum.Notes :
        this.selectedLearnTool = LearnToolsEnum.Notes;
        break;
      case LearnToolsEnum.FileAttachment :
        this.selectedLearnTool = LearnToolsEnum.FileAttachment;
        break;
      case LearnToolsEnum.Live :
        this.selectedLearnTool = LearnToolsEnum.Live;
        break;
      case LearnToolsEnum.VirtualMachineView:
        this.selectedLearnTool = LearnToolsEnum.VirtualMachineView;
        this.guacMultiscreenDisconnect = [true];
        setTimeout(()=>{
          this.tabGuacamoleJwt = undefined;
          this.guacMultiscreenDisconnect = [false];
          this.createGuacamoleMultiscreenKey();
          this.getVmConnections();
        },500)
        break;
    }
  }
  selectedTool(learnTools:LearnToolsEnum){
    if(learnTools == this.selectedLearnTool){
      return true;
    }else{
      return false;
    }
  }
  fullscreenListener(){
    document.addEventListener('fullscreenchange',(ev)=>{
      if(document.fullscreenElement){
        this.fullscreen = true;
      }else{
        this.fullscreen = false;
      }
    })
  }
  requestFullscreen(){
    let content = document.getElementsByClassName("learnMain")[0] as HTMLDivElement;
    let body = document.body.style;
    console.log(body)
    content.style.background = "#efefef";
    content.requestFullscreen()
    this.fullscreen = true;
  }
  closeFullscreen(){
    document.exitFullscreen();
    this.fullscreen = false;
  }
  readHtmlFromSummernote(){
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    let code = $(editNoteContent).summernote('code') as string;
    console.log(code);
  }
  saveNote(){
    let noteTitleElement = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    let noteTitle = noteTitleElement.value;
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    let noteContent = $(editNoteContent).summernote('code') as string;
    let noteId = this.noteEditId ?? "";
    if(noteId == ""){
      this.toastrService.error("Not güncellenemedi");
      return;
    };
    let updateNoteModel:UpdateNoteModel = {title:noteTitle,noteContent:noteContent,id:noteId};
    console.log(updateNoteModel);
    this.learnService.updateNote(updateNoteModel,this.courseId).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success("Not başarıyla eklendi");
          this.destroySummerNote();
        }else{
          this.toastrService.error("Not eklenirken bir hata oluştu");
        }
      },
      error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
    this.destroySummerNote();
  }
  createNote(){
    let noteTitleElement = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    let contentId = this.learnContent.currentContent.id;
    let noteTitle = noteTitleElement.value;
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    let noteContent = $(editNoteContent).summernote('code') as string;
    let createNoteModel:CreateNoteModel = {contentId:contentId,noteContent:noteContent,title:noteTitle};
    console.log(createNoteModel);
    this.learnService.createNote(createNoteModel,this.courseId).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success("Not başarıyla eklendi");
          this.destroySummerNote();
        }else{
          this.toastrService.error("Not eklenirken bir hata oluştu");
        }
      },
      error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  private destroySummerNote(){
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    $(editNoteContent).summernote('destroy');
    this.editMode = false;
    let summerNoteDiv = this.noteWindow.document.getElementById('editNoteContent') as HTMLDivElement;
    let editNoteTitle = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    summerNoteDiv.innerHTML = ""
    editNoteTitle.value = "";
  }
  editNote(note:StudentNoteModel){
    this.editMode = true;
    let summerNoteDiv = this.noteWindow.document.getElementById('editNoteContent') as HTMLDivElement;
    let editNoteTitle = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    summerNoteDiv.innerHTML = note.noteContent;
    editNoteTitle.value = note.title ?? "";
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    $(editNoteContent).summernote({height:200});
    this.noteEditId = note.id;
  }

  newNote(){
    this.noteEditId = undefined;
    this.editMode = true;
    let summerNoteDiv = this.noteWindow.document.getElementById('editNoteContent') as HTMLDivElement;
    let editNoteTitle = this.noteWindow.document.getElementById('editNoteTitle') as HTMLInputElement;
    summerNoteDiv.innerHTML = ""
    editNoteTitle.value = "";
    let editNoteContent = this.noteWindow.document.getElementById("editNoteContent")
    $(editNoteContent).summernote({height:200});
  }

  deleteNote(courseId:string,noteId:string){
    this.learnService.deleteNote(noteId,courseId).subscribe({
      next:(response)=>{
        if(response.success){
          this.toastrService.success("Not başarıyla silindi");
        }else{
          this.toastrService.error("Not silinemedi");
        }
      },
      error:(err)=>{
        this.toastrService.error("Bir hata oluştu");
      }
    })
  }
  cancelNote(){
    this.noteEditId = undefined;
    this.destroySummerNote();
  }
  showSharedConnections(userId:string){
    return new Promise((resolve,reject)=>{
      let sessionName = this.learnContent.courseInfo.features.session.sessionId
      this.sharedConnections = [];
      return this.guacamoleService.getGuacamoleSharedConnections(sessionName).subscribe({
        next:(sharedConnectionsList)=>{
          console.log(sharedConnectionsList);
          //sharedConnectionsList = sharedConnectionsList.content.filter(x=>x.userId == userId);
          this.sharedConnections = sharedConnectionsList.content.filter(x=>x.userId == userId);
          resolve(true);
        },error:(err)=>{
          resolve(false);
        }
      })
    })

  }
  connectToSharedConnection(guacItem:GuacamoleSharedConnectionDto){
    var remoteClientSessionModel:RemoteClientSessionModel = {expiresIn:36000,session:guacItem.guacSessionId};
    this.guacamoleService.getGuacamoleSharedSessionToken(remoteClientSessionModel).subscribe({
      next:(response)=>{
        let guacToken = response.content;
        let vm = guacItem.connectionName;
        let userId = guacItem.userId;
        let websocketUrl = this.learnContent.courseInfo.features.session.websocketUrl;
        let sessionId = this.learnContent.courseInfo.features.session.sessionId;
        let sessionData:{sessionId:string,sessionSocket:string,userId:string,sharedConnection:boolean,chatSocket:string,chatApi:string,chatRoom:string,displayName:string}

        let chatSocketUrl = this.learnContent.courseInfo.features.textchat.websocketUrl;
        let chatApiUrl = this.learnContent.courseInfo.features.textchat.apiUrl;
        let chatRoom = this.learnContent.courseInfo.features.textchat.room;
        let displayName = this.learnContent.members.find(x=>x.userId == guacItem.userId)?.displayName ?? "User";

        sessionData = {sessionId:sessionId,sessionSocket:websocketUrl,userId:userId,sharedConnection:true,chatApi:chatApiUrl,chatSocket:chatSocketUrl,chatRoom:chatRoom,displayName:displayName};
        let sessionDataJson = JSON.stringify(sessionData);
        let connectKey = this.generateUUID();
        localStorage.setItem(connectKey,sessionDataJson)
        this.sessionSocket.terminalSessionSharedJoin(sessionId,userId,vm);
        window.open("/remoteClient/"+guacToken+"/"+vm+"/"+connectKey,'_blank');
      }
    })
  }
  deleteHelpRequest(userId:string,terminalId:string){
    this.helpRequests = this.helpRequests.filter(x=>x.userId !== userId && x.terminalId !== terminalId);
  }
  helpRequestConnector(userId:string,terminalId:string){
    let item = this.getSharedConnectionItem(userId,terminalId).then(data=>{
      if(data != undefined){
        this.connectToSharedConnection(data);
      }else{
        this.toastrService.error("Bir hata oluştu");
      }
    });
  }
  helpRequestListener(){
    this.sessionSocket.askForHelpListener.subscribe((data)=>{
      console.log(data);
      console.log(this.sessionId);
      var isUserRequest = this.helpRequests.find(x=>x.userId == data.userId && x.terminalId == data.terminalId);
      if(isUserRequest == undefined){
        var displayName = data.displayName as string;
        var userId = data.userId as string;
        var terminalId = data.terminalId as string;
        this.helpRequests.push({displayName,userId,terminalId});
      }
      if(data.sessionId == this.sessionId){
        this.toastrService.info(`Kullanıcı :  ${data.displayName} <br/> Terminal :  ${data.terminalId} <br/> <font style='font-size:13px;'>Tıklayarak bağlantıya gidebilirsiniz.</font>`,"Yardım İsteği",{enableHtml:true}).onTap.subscribe(response=>{
          let item = this.getSharedConnectionItem(data.userId,data.terminalId).then(data=>{
            if(data != undefined){
              this.connectToSharedConnection(data);
            }else{
              this.toastrService.error("Bir hata oluştu");
            }
          });
        })
      }
    })
  }
  getSharedConnectionItem(userId:string,vmName:string)
  {
    return new Promise<GuacamoleSharedConnectionDto | undefined>((resolve,reject)=>{
      let guacItem:GuacamoleSharedConnectionDto | undefined= undefined;
      this.showSharedConnections(userId).then(x=>{
        if(x == true){
          guacItem = this.sharedConnections.find(x=>x.userId == userId && x.connectionName == vmName);
          resolve(guacItem);
        }
      })

    })


  }
  createGuacamoleMultiscreenKey(){
    let sessionId = this.learnContent.courseInfo.features.session.sessionId
    var model:RemoteClientSessionModel = {expiresIn:36000,session:""};
    this.guacamoleService.getGuacamoleSessionIdWithSessionName(sessionId).subscribe({
      next:(guacSession)=>{
        model.session = guacSession.content.sessionId;
        this.guacamoleService.getGuacamoleSessionToken(model).subscribe({
          next:(jwt)=>{

            this.guacamoleService.getGuacamoleAuthToken({data:jwt.content}).subscribe({
              next:(response)=>{
                let guacConnectionInfo:GuacConnectionInfo = {url:`//${window.location.hostname}`,wsUrl:`wss://${window.location.hostname}`,signalRSocket:"/hubs/session",mainPath:"/guacamole/"};
                this.guacAuthService.setGuacamoleParams(guacConnectionInfo);
                localStorage.setItem("GUAC_AUTH",`${JSON.stringify(response)}`);
                console.log(localStorage.getItem("GUAC_AUTH"));
                this.tabGuacamoleJwt = jwt.content;

              },error:(err)=>{

              }
            })

          },error:()=>{

          }
        })
      },error:()=>{

      }
    })
  }
  useGuacamoleMultiscreen(){
    this.loadingMultiscreen = true;
    let sessionId = this.learnContent.courseInfo.features.session.sessionId
    var model:RemoteClientSessionModel = {expiresIn:36000,session:""};
    this.guacamoleService.getGuacamoleSessionIdWithSessionName(sessionId).subscribe({
      next:(guacSession)=>{
        model.session = guacSession.content.sessionId;
        this.guacamoleService.getGuacamoleSessionToken(model).subscribe({
          next:(jwt)=>{
            console.log("guac jwt");
            console.log(jwt);
            this.guacamoleService.getGuacamoleAuthToken({data:jwt.content}).subscribe({
              next:(response)=>{
                let guacConnectionInfo:GuacConnectionInfo = {url:`//${window.location.hostname}`,wsUrl:`wss://${window.location.hostname}`,signalRSocket:"/hubs/session",mainPath:"/guacamole/"};
                this.guacAuthService.setGuacamoleParams(guacConnectionInfo);
                localStorage.setItem("GUAC_AUTH",`${JSON.stringify(response)}`);
                console.log(localStorage.getItem("GUAC_AUTH"));
                this.loadingMultiscreen = false;
                window.open("/terminal/"+jwt.content+"/all",'',"width=900, height=800");

              },error:(err)=>{
                this.loadingMultiscreen = false;
              }
            })

          },error:()=>{
            this.loadingMultiscreen = false;
          }
        })
      },error:()=>{
        this.loadingMultiscreen = false;
      }
    })
  }
  guacamoleStart(){
    console.log("guacstart")
    let sessionId = this.learnContent.courseInfo.features.session.sessionId
    var model:RemoteClientSessionModel = {expiresIn:36000,session:""};
    this.guacamoleService.getGuacamoleSessionIdWithSessionName(sessionId).subscribe({
      next:(guacSession)=>{
        model.session = guacSession.content.sessionId;
        this.guacamoleService.getGuacamoleSessionToken(model).subscribe((response)=>{
          console.log(response);
          this.guacToken = response.content;
          this.guacamoleService.getMyMachines(response.content).subscribe((data)=>{
            console.log("data");
            console.log(data);
            this.guacMachines = data.content;
          })
        })
      },error:(err)=>{
        console.log(err);
        this.toastrService.error("Uzak makineler alınırken bir sorun oluştu");
      }
    })
  }
  directConnect(vm:GuacamoleSharedConnectionDto){
    //this.router.navigate([']);
    let websocketUrl = this.learnContent.courseInfo.features.session.websocketUrl;
    let sessionId = this.learnContent.courseInfo.features.session.sessionId;

    let sessionData:{sessionId:string,sessionSocket:string,userId:string,sharedConnection:boolean}
    sessionData = {sessionId:sessionId,sessionSocket:websocketUrl,userId:vm.userId,sharedConnection:false};
    let sessionDataJson = JSON.stringify(sessionData);
    let connectKey = this.generateUUID();
    localStorage.setItem(connectKey,sessionDataJson)
    window.open("/remoteClient/"+this.guacToken+"/"+vm.connectionName+"/"+connectKey,'_blank');
  }
  guacamoleMultiscreenDirectConnect(vmName:string){
    let sessionId = this.learnContent.courseInfo.features.session.sessionId;
    this.directConnect({connectionName:vmName,sessionName:sessionId,userId:"",guacSessionId:"",sharedConnectionId:""})
  }
  isHaveDirectConnect(vm:string){
    let terminal = this.guacMachines.find(x=>x == vm);
    if(terminal == undefined){
      return false;
    }
    return true;
  }
  generateUUID():string { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}
getVmConnections(){
  this.guacamoleService.getConnectedUsersWithVmNames().subscribe({
    next:(response)=>{
      this.guacamoleVmConnections = response;
    },error:(err)=>{
      this.toastrService.error("An error occurred");
    }
  })
}
showConnectedUsers(vmName:string){
  var showDiv = document.createElement("div");
  this.guacamoleService.getConnectedUsersWithVmNames([vmName]).subscribe({
    next:(response)=>{
      var users = response[0].connections;
      if(users){
        users.forEach(user=>{
          var userDiv = document.createElement("div");
          userDiv.style.padding = "8px";
          userDiv.style.borderRadius = "12px";
          userDiv.style.background = "#fefefe";
          userDiv.style.marginBottom = "3px";
          var userIcon = document.createElement("i");
          userIcon.classList.add("bi","bi-person");
          var username = document.createElement("b");
          username.innerText = " "+user.username;
          userDiv.append(userIcon);
          userDiv.append(username);
          showDiv.append(userDiv);
        })
      }
      this.globalModalService.showModal({
        data:showDiv,
        helperModals:[],
        title:"Active Users",
        type:ModalTypes.HTMLELEMENT
      })
    },error:(err)=>{

    }
  })
}

}
