<div class="instructorActionsDiv">
  <div *ngIf="sharedConnection" class="sharedActions actions">
    <button class="btn btn-sm btn-success" (click)="changeTerminalAccess(true)"><i class="bi bi-mouse2"></i> Öğrenci
      Erişimine İzin Ver</button>
    &nbsp;
    <button class="btn btn-sm btn-danger" (click)="changeTerminalAccess(false)"><i class="bi bi-dash-circle-fill"></i>
      Öğrenci Erişimini Engelle</button>
  </div>
  <div class="directActions actions">

  </div>
  <div class="commonActions actions">
    <i style="font-size:20px;" data-bs-toggle="modal" data-bs-target="#staticBackdrop" (click)="openChat()" *ngIf="sharedConnection" class="bi bi-chat-dots-fill"></i>
    <div class="vmNameAction">
      <i class="bi bi-display"></i>
      <font class="vmNameText"> {{vmName}}</font>
    </div>
  </div>
</div>
<div class="connectingMachine" [class.displayNone]="state == 3">
  <div class="connectingLogo" *ngIf="logoUrl">
    <img [src]="logoUrl"/>
  
  </div>
  <div class="connectingTitle" *ngIf="logoUrl == ''">Remote Desktop Client</div>
  <div class="connectingMessage">
    <p>{{loaderMessage}}</p>
    <div *ngIf="state == 1 || state == 2" class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

</div>
<div>
  <lib-guac-client (outTunnelState)="changeTunnelState($event)" [keyboardAccess]="keyboardAccess" [mouseAccess]="mouseAccess" *ngIf="start" [sessionJwt]="jwt"
    [extClientId]="vmName" (outState)="clientStateChanged($event)"></lib-guac-client>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
          <lib-terminal-chat-sender [messageHistory]="privateMessages" (message)="sendTerminalMessage($event)" [userList]="terminalUser"></lib-terminal-chat-sender>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal" (click)="closeChat()">Sekmeyi Kapat</button>
      </div>
    </div>
  </div>
</div>

