import { Component, Input, OnInit } from '@angular/core';
import { UpcomingCoursesModel } from '../../models/upcomingCoursesModel';

@Component({
  selector: 'app-instructor-course-item',
  templateUrl: './instructor-course-item.component.html',
  styleUrls: ['./instructor-course-item.component.css']
})
export class InstructorCourseItemComponent implements OnInit {
  @Input() courseItem:UpcomingCoursesModel
  constructor() { }

  ngOnInit(): void {
    console.log(this.courseItem)
  }

}
