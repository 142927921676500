import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoggedUserListenerService } from "projects/usorta-library/src/lib/services/listener/logged-user-listener.service";
import { LoginService } from "projects/usorta-library/src/lib/services/login/login.service";
import { NavbarMenuItem } from "../../models/navbarMenuItem";
import { NavigationBarService } from "../../services/navigation-bar.service";
import { MenuItems } from "projects/usorta-library/src/lib/models/menu/menuItems";

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css']
})
export class NavigationBarComponent implements OnInit {
  @Input() menuItems:MenuItems[];
  loginSubscribe:any;
  constructor(private navigationBarService:NavigationBarService,private router:Router,private loggedUserListener:LoggedUserListenerService,private loginService:LoginService) { }

  ngOnInit(): void {
    //this.getNavbarMenuItems();
  }
  getNavbarMenuItems(){
    this.navigationBarService.getMenu().subscribe({
      next:response=>{
        this.menuItems = response;
      },
      error:error=>{
        console.log(error);
      }
    })
  }
  logout(){
    this.loginService.logout();
    if(this.loginSubscribe){
      this.loginSubscribe.unsubscribe();
    }
    this.loginSubscribe = this.loggedUserListener.getLoggedUserStatus('false').subscribe(response=>{
      this.router.navigate(['/login']);
    });
  }
  getTarget(item:NavbarMenuItem){
    if(item.target){
      return item.target;
    }else{
      return '_self';
    }
  }
  redirect(item:NavbarMenuItem){
    console.log(item.link);
    if(item.link.startsWith('http') || item.link.startsWith('https')){
      window.open(item.link,this.getTarget(item));
    }else{
      this.router.navigate([item.link]);
    }
  }
  isActivePage(item:NavbarMenuItem){
    let itemPage = item.link.split('/')[1];
    let currentUrl = this.router.url.split('/')[1];
    if(itemPage === currentUrl){
      return true;
    }else{
      return false;
    }
  }


}
