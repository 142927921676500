<div class="row content" (click)="goToReplyUrl(messageItem)">
  <div class="col-2 col-lg-1">
    <div style="position: relative;">
      <img src="{{messageItem.userPicture}}" style="width: 100%; position: absolute; top:20px">
    </div>

  </div>
  <div class="col-lg-11 col-10">
    <div class="userFirstName">{{messageItem.userFirstName}}</div>
    <div class="userMessage">{{messageItem.message}}</div>
    <div class="messageDetails">
      <div class="row">
        <div class="col-md-6">
          <div class="senderEmail">
            {{messageItem.userEmail}}
          </div>
        </div>
        <div class="col-md-6">
          <div class="receivedDate" style="float: right;">
            {{messageItem.receivedDate}} <i *ngIf="messageItem.important" title="Önemli" class="bi bi-flag-fill" style="color:red;"></i>
          </div>
        </div>
      </div>


    </div>

  </div>
</div>
