<div *ngIf="messageBox">
  <div class="messagesHeader">
    <h4 style="display: inline-block; margin-right: 6px;">{{'messageBox' | translate}} </h4><span style="position:relative; bottom:4px;" class="badge bg-secondary">{{messageBox.newMessageCount}}</span><br/>
  </div>
  <div class="messagesContent">
    <div class="messageItem" *ngFor="let item of importantMessages">
      <app-message-item [messageItem]="item"></app-message-item>
    </div>
    <div class="messageItem" *ngFor="let item of normalMessages">
      <app-message-item [messageItem]="item"></app-message-item>
    </div>
  </div>
</div>
