
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CalendarEvent } from './../../models/calendarModel';
import { CalendarService } from './../../services/calendar.service';
import { Component, OnInit } from '@angular/core';
import { CalendarRequestModel, CalendarResponseModel } from '../../models/calendarModel';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  constructor(private calendarService:CalendarService,private router:Router,public translateService:TranslateService) { }
  calendarRequest:CalendarRequestModel;
  calendarResponse:CalendarResponseModel;
  firstDay:number;
  tempDays:number[] = [];
  dayCount:number = 0;
  tempDates:Date[] = [];
  dates:Date[] = [];
  pushDate:Date;
  showEvents:CalendarEvent[];
  showDate:Date;
  showPanel:boolean = false;
  dateFormat:string;
  dateTimeFormat:string;
  timeFormat:string;
  ngOnInit(): void {
    this.getCalendar();
    //this.dateFormat="dd/MM/YYYY";
    this.dateFormat = "dd/MM/YYYY";
    this.dateTimeFormat = "dd/MM/YYYY HH:mm";
    this.timeFormat = "HH:mm";
  }
  getCalendar(){
    let date:Date = new Date();
    let endDate:Date = new Date();
    endDate.setDate(endDate.getDate() + 30);
    this.calendarRequest = {startDate:date,endDate:endDate};
    this.calendarService.getCalendar(this.calendarRequest).subscribe({
      next:(data)=>{
        this.calendarResponse = data;
        let currentDate = new Date(data.startDate);
        currentDate.setHours(0,0,0,0);
        let endDate = new Date(data.endDate);
        endDate.setHours(0,0,0,0);
        this.firstDay = currentDate.getDay();
        data.calendar.forEach(element => {
          element.calendarDate = new Date(element.calendarDate);
          element.calendarDate as Date
          element.calendarDate.setHours(0,0,0,0);
        })
        for (let index = 1; index < this.firstDay; index++) {
          this.tempDays.push(index);
        }
        this.pushDate = new Date(currentDate);
        var diffDays:number = (endDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);
        for (let index = 0; index <= diffDays; index++) {
          this.tempDates.push(new Date(this.pushDate))
          this.pushDate.setDate(this.pushDate.getDate() + 1);
        }
        this.dates = this.tempDates;
      }
    })

  }
  getEventsByDate(date:Date){
    let events:CalendarEvent[] = [];
    this.showDate = date;
    this.calendarResponse.calendar.forEach(element => {
      if(element.calendarDate.getTime() == date.getTime()){
        events = element.events
      }
    }
    );
    return events;

  }
  showDateEvents(date:Date){
    this.showEvents = this.getEventsByDate(date);
    this.showPanel = true;
  }
  hideDateDetails(){
    this.showPanel = false;
  }
  redirect(link:string){
    console.log(link);
    if(link.startsWith('http') || link.startsWith('https')){
      window.open(link);
    }else{
      this.router.navigate([link]);
    }
  }
}
