import { MessageItem } from './../../models/messageBox';
import { MessagesService } from './../../services/messages.service';
import { Component, OnInit } from '@angular/core';
import { MessageBox } from '../../models/messageBox';

@Component({
  selector: 'app-messages-summary',
  templateUrl: './messages-summary.component.html',
  styleUrls: ['./messages-summary.component.css']
})
export class MessagesSummaryComponent implements OnInit {
  messageBox:MessageBox;
  importantMessages:MessageItem[];
  normalMessages:MessageItem[];
  constructor(private messagesService:MessagesService) { }

  ngOnInit(): void {
    this.getMessages();
  }
  getMessages(){
    this.messagesService.getMessageSummary().subscribe({
      next:(data)=>{
        this.messageBox = data;
        this.importantMessages = this.messageBox.newMessages.filter(x=>x.important);
        this.normalMessages = this.messageBox.newMessages.filter(x=>!x.important);
      },
      error:(err)=>{
        console.log(err);
      }
    });
  }

}
