import { Observable } from 'rxjs';
import { ApiUrlService } from './../../../../usorta-library/src/lib/services/api/api-url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LobbyModel } from '../models/lobbyModel';

@Injectable({
  providedIn: 'root'
})
export class LobbyService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  getLobby(courseId:string):Observable<LobbyModel>{
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<LobbyModel>(apiUrl + "lobby/" + courseId);
  }
}
