<div class="row">
  <div class="col-md-7">
    <div class="row">
      <div class="col-xs-12">
        <div class="courseInfo">
          <h3><i class="bi bi-info-circle"></i> {{'courseInfo' | translate}}</h3>
          <hr>
          <font class="courseName">{{lobbyModel.info.courseName}}</font>
          <br/>
          <img class="courseImage" src="{{lobbyModel.info.picUrl}}"/>
          <br/>
          <font class="courseDescription">{{lobbyModel.info.courseDescription}}</font>
        </div>
        <div class="courseOutline">
          <h3><i class="bi bi-card-list"></i> {{'courseOutline' | translate}}</h3>
          <hr>
          <div class="courseOutlineBody">
          <div *ngFor="let item of lobbyModel.outline">
            <div class="courseOutlineItem">
              <div class="courseOutlineItemTitle" [class.pointer]="item.url" (click)="redirect(item.url)">
                <i class="{{item.preIcon}}"></i><font> {{item.name}} </font><i class="{{item.postIcon}}"></i>
              </div>
              <div class="courseOutlineItemDescription">
                <font>{{item.description}}</font>
              </div>
              <hr>
              <div class="subCourseItem" *ngFor="let subItem of item.subItems" >
                <div class="courseOutlineItemTitle" [class.pointer]="subItem.url" (click)="redirect(subItem.url)">
                  <i class="{{subItem.preIcon}}"></i><font> {{subItem.name}} </font><i class="{{subItem.postIcon}}"></i>
                </div>
                <div class="courseOutlineItemDescription">
                  <font>{{subItem.description}}</font>
                </div>
                <hr>
              </div>
            </div>
        </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-5">
    <div class="row">
      <div class="col-xs-12">
        <div class="sessionDiv">
          <h3><i class="bi bi-play-circle"></i> {{'sessionManagement' | translate}}</h3>
          <hr>
          <button class="btn btn-success" style="margin-right: 3px; margin-bottom: 3px;"><i class="bi bi-play-fill"></i> {{'startSession' | translate}}</button>
          <button class="btn btn-danger" style="margin-right: 3px; margin-bottom: 3px;"><i class="bi bi-stop-fill"></i> {{'stopSession' | translate}}</button>
          <button *ngFor="let link of lobbyModel.session.links" class="btn btn-secondary" style="margin-right: 3px; margin-bottom: 3px;" title="{{link.tooltip}}" (click)="redirect(link.url)" ><i class="{{link.icon}}"></i> {{link.text}}</button>
        </div>
        <div class="sessionDiv">
          <h3><i class="bi bi-people"></i> {{'studentList' | translate}}</h3>
          <hr>
          <div class="studentList">
            <div [class.pointer]="student.url" (click)="redirect(student.url)" id="{{student.id}}" class="studentListItem" title="{{student.email}}" *ngFor="let student of lobbyModel.students">
              <div class="studentProfilePicture">
                <img *ngIf="student.profilePic != ''" src="{{student.profilePic}}"/>
                <i *ngIf="!student.profilePic" class="bi bi-person-fill"></i>
              </div>
              <div class="studentName">
                <font> {{student.name}}</font>
              </div>
              <div class="studentStatus" class="{{student.isOnline ? 'onlineStatus' : 'offlineStatus'}}"></div>
            </div>
            <div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="row">
  <div class="col-md-8">
    <div class="row">
      <div class="col-xs-12">
        dasdasdasdadas<br/>
        dasasdasdasdasd
        <br/>dasdasdasdas
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="row">
      <div class="col-xs-12">

      </div>
    </div>
  </div>
</div>-->
