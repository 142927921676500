<div class="courseDiv">
  <img class="courseImage" src="{{courseItem.picUrl}}"><br/>
  <div class="categoryName" [style]="'background-color:'+courseItem.color+';'"> {{courseItem.category}}</div>
  <div class="courseName"> {{courseItem.title}}</div>
  <div class="courseDetails">
    <font class="detailText"><b>{{'courseDate' | translate}} : </b>{{courseItem.startsAt}}</font><br/>
    <font class="detailText"><b>{{'participantInfo' | translate}} : </b>{{courseItem.studentCount}}/{{courseItem.maxStudentCount}}</font>

  </div>
  <div class="links">
    <button *ngFor="let link of courseItem.links" class="btn btn-sm" [style]="'color:white; margin-left:3px; background-color:'+courseItem.color+';'" routerLink="{{link.url}}">{{link.text}}</button>
  </div>

</div>
