import { ActivatedRoute, Router } from '@angular/router';
import { LobbyService } from './../../services/lobby.service';
import { LobbyModel } from './../../models/lobbyModel';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-lobby',
  templateUrl: './course-lobby.component.html',
  styleUrls: ['./course-lobby.component.css']
})
export class CourseLobbyComponent implements OnInit {

  constructor(private lobbyService:LobbyService,private router:Router,private activatedRoute:ActivatedRoute) { }
  lobbyModel:LobbyModel
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params=>{
      this.getLobby(params['course']);
    })

  }
  getLobby(courseId:string){
    this.lobbyService.getLobby(courseId).subscribe(response=>{
      this.lobbyModel = response;
    })
  }
  startSession(){

  }
  stopSession(){

  }
  redirect(link:string){
    console.log(link);
    if(link.startsWith('http') || link.startsWith('https')){
      window.open(link);
    }else{
      this.router.navigate([link]);
    }
  }

}
