import { UpcomingSummaryService } from './../../services/upcoming-summary.service';
import { Component, OnInit } from '@angular/core';
import { UpcomingCoursesModel } from '../../models/upcomingCoursesModel';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  upcomingCourses:UpcomingCoursesModel[];
  constructor(private upcomingSummaryService:UpcomingSummaryService) { }

  ngOnInit(): void {
    this.getUpcomingCourses();
  }
  getUpcomingCourses(){
    this.upcomingSummaryService.upcomingEvents().subscribe({
      next:response=>{
        this.upcomingCourses = response;
      },
      error:error=>{
        console.log(error);
      }

    })
  }


}
