<!-- Senkron Eğitim -->
<lib-session-loader [startSocket]="startSocket" *ngIf="socketConnected == false || sessionStatus != 1"
  [error]="[socketConnectedError]" [status]="[sessionStatus]"></lib-session-loader>
<div class="learnMain">
  <div [class.displayNone]="socketConnected == false || sessionStatus !=1" class="row columnBorderTop "
    style="margin-bottom:100px" *ngIf="learnContent">
    <div class="col-2" id="userList">
      <div class="member" data-bs-toggle="offcanvas" data-bs-target="#userOffcanvas"
        (click)="showSharedConnections(learnMember.userId)" *ngFor="let learnMember of learnContent.members"
        [id]="learnMember.userId">
        <div class="memberLeftContent">
          <i class="bi bi-person-circle personIcon"></i>
          <font> <b> {{learnMember.displayName}}</b></font>
        </div>
        <div class="memberRightContent">
          <div [id]="'status_'+learnMember.userId" class="statusDiv"></div> &nbsp;
          <i *ngIf="raiseHandListControl(learnMember.userId)" class="bi bi-hand-index-fill raiseHandUserList"></i>
          <div *ngIf="lastPoll && lastPoll.pollId != null" style="float:right;">
            <i style="font-size:20px;" *ngIf="showCurrentPollVote(learnMember.userId) == 'T'"
              class="bi bi-check-lg checkIcon"></i>
            <i style="font-size:20px;" *ngIf="showCurrentPollVote(learnMember.userId) == 'F'"
              class="bi bi-x-lg xIcon"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="outline col-2" id="outline" *ngIf="learnContent.courseOutline">
      <h5>{{"courseSections" | translate}}</h5>
      <lib-course-outline [courseOutline]="learnContent.courseOutline"></lib-course-outline>
    </div>
    <div class="learnContent col-6" style="white-space: normal;" id="learnContent">
      <div class="courseTitle">
        <h5>{{learnContent.courseInfo.title}}</h5>
      </div>
      <ul class="nav nav-tabs" style="margin-bottom:5px;">
        <li class="nav-item">
          <a class="nav-link" [class.active]="selectedTool(0)" (click)="switchLearnTool(0)">{{'tools.courseContent'
            |translate}} <i *ngIf="selectedLearnTool == 0 && mainPageWindow == courseContentWindow"
              (click)="popOut(selectedLearnTool)" class="bi bi-window-plus popOutIcon"></i></a>
        </li>
        <li class="nav-item" *ngIf="learnContent.courseInfo.features.whiteboard.enabled">
          <a class="nav-link" [class.active]="selectedTool(1)" (click)="switchLearnTool(1)">{{'tools.whiteboard'
            |translate}} <i *ngIf="selectedLearnTool == 1 && mainPageWindow == whiteboardWindow"
              (click)="popOut(selectedLearnTool)" class="bi bi-window-plus popOutIcon"></i></a>
        </li>
        <li class="nav-item" *ngIf="learnContent.courseInfo.features.textchat.enabled">
          <a class="nav-link" [class.active]="selectedTool(2)" (click)="switchLearnTool(2)">{{'tools.chat' |translate}}
            <i *ngIf="newMessageReceived" class="bi bi-dot messageNotificationDot"
              style="font-size:30px; position:relative;"></i> <i
              *ngIf="selectedLearnTool == 2 && chatWindow[0] == mainPageWindow" (click)="popOut(selectedLearnTool)"
              class="bi bi-window-plus popOutIcon"></i> </a>
        </li>
        <li class="nav-item" *ngIf="learnContent.courseInfo.features.notes.enabled">
          <a class="nav-link" [class.active]="selectedTool(3)" (click)="switchLearnTool(3)">{{'tools.notebook'
            |translate}} <i *ngIf="selectedLearnTool == 3 && mainPageWindow == noteWindow"
              (click)="popOut(selectedLearnTool)" class="bi bi-window-plus popOutIcon"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="selectedTool(4)" (click)="switchLearnTool(4)">{{'tools.fileAttachments'
            |translate}} <i *ngIf="selectedLearnTool == 4 && mainPageWindow == fileAttachmentWindow"
              (click)="popOut(selectedLearnTool)" class="bi bi-window-plus popOutIcon"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="selectedTool(5)" (click)="switchLearnTool(5)">{{'tools.stream'
            |translate}}
            <i *ngIf="selectedLearnTool == 5 && mainPageWindow == streamWindow" (click)="popOut(selectedLearnTool)"
              class="bi bi-window-plus popOutIcon"></i></a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
            aria-expanded="false"></a>
          <ul class="dropdown-menu">
            <li><a class="nav-link" [class.active]="selectedTool(6)" (click)="switchLearnTool(6)">Sanal Makineler
                <!--<i *ngIf="selectedLearnTool == 6 && mainPageWindow == guacamoleScreensWindow"
                  (click)="popOut(selectedLearnTool)" class="bi bi-window-plus popOutIcon"></i>-->
                  <i *ngIf="selectedLearnTool == 6 && mainPageWindow == guacamoleScreensWindow"
                  (click)="useGuacamoleMultiscreen()" class="bi bi-window-plus popOutIcon"></i>
                </a></li>
          </ul>

        </li>
        <form class="d-flex">
          <a class="nav-link" *ngIf="fullscreen == false" (click)="requestFullscreen()"><i class="bi bi-fullscreen"
              title="İçeriği tam ekrana al"></i></a>
          <a class="nav-link" *ngIf="fullscreen == true" (click)="closeFullscreen()"><i
              class="bi bi-fullscreen-exit"></i></a>
        </form>
      </ul>
      <div *ngIf="learnContent.currentContent" [hidden]="selectedTool(0) == false"
        class="courseContent courseContentTextMain contentDiv_0">
        <lib-popout-message *ngIf="courseContentWindow != mainPageWindow" [mainWindow]="mainPageWindow"
          [popOutWindow]="courseContentWindow"></lib-popout-message>
        <div class="content_0">
          <div class="content courseContentText" *ngFor="let contentScheme of learnContent.currentContent.content">
            <div *ngIf="contentScheme.format == 0" class="plainText" [innerText]="contentScheme.content"></div>
            <div *ngIf="contentScheme.format == 1" class="html" [innerHTML]="contentScheme.content"></div>
            <markdown *ngIf="contentScheme.format == 2" [data]="contentScheme.content" mermaid katex></markdown>
          </div>
        </div>
      </div>
      <div *ngIf="learnContent.courseInfo.features.whiteboard.enabled" [hidden]="selectedTool(1) == false"
        class="courseContent whiteboardMainDiv contentDiv_1">
        <lib-popout-message *ngIf="whiteboardWindow != mainPageWindow" [mainWindow]="mainPageWindow"
          [popOutWindow]="whiteboardWindow"></lib-popout-message>
        <div *ngIf="false" class="whiteboardContent">

        </div>
        <div class="whiteboardModule content_1" style="overflow-x: scroll; white-space: nowrap;">
          <lib-canvas [imageMainPath]="'/uploads/'" [imageUploadEndpoint]="'/api/v2/whiteboard/draw/addimage'"
            [requestedRoom]="learnContent.courseInfo.features.whiteboard.room"
            [apiUrl]="learnContent.courseInfo.features.whiteboard.apiUrl"
            [websocketURL]="learnContent.courseInfo.features.whiteboard.websocketUrl"></lib-canvas>
        </div>
      </div>
      <div *ngIf="learnContent.courseInfo.features.textchat.enabled" [hidden]="selectedTool(2) == false"
        class="courseContent contentDiv_2">
        <lib-popout-message *ngIf="chatWindow[0] != mainPageWindow" [mainWindow]="mainPageWindow"
          [popOutWindow]="chatWindow[0]"></lib-popout-message>
        <div>
          <lib-chat class="content_2" [inputWindow]="chatWindow" (newMessageOutput)="messageToast($event)"
            [chatRoomId]="learnContent.courseInfo.features.textchat.room"
            [apiUrl]="learnContent.courseInfo.features.textchat.apiUrl"
            [websocketUrl]="learnContent.courseInfo.features.textchat.websocketUrl"></lib-chat>
        </div>
      </div>
      <div *ngIf="learnContent.courseInfo.features.notes.enabled" [hidden]="selectedTool(3) == false"
        class="courseContent contentDiv_3">
        <lib-popout-message *ngIf="noteWindow != mainPageWindow" [mainWindow]="mainPageWindow"
          [popOutWindow]="noteWindow"></lib-popout-message>
        <div class="content_3">
          <!--<div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item" *ngFor="let note of learnContent.notes">
            <h2 class="accordion-header" id="flush-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapseNote_'+note.id" aria-expanded="false"
                [attr.aria-controls]="'collapseNote_'+note.id">
                {{note.title ?? "Başlıksız Not"}}
              </button>
            </h2>
            <div [id]="'collapseNote_'+note.id" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body" [innerHTML]="note.noteContent">

              </div>
              <div class="accordion-footer" style="text-align: right; padding: 5px;">
                <button class="btn btn-sm btn-danger me-1" (click)="deleteNote(courseId,note.id??'')"><i
                    class="bi bi-trash-fill"></i> {{'btn.delete' |translate}}</button>
                <button class="btn btn-sm btn-primary" [disabled]="editMode" (click)="editNote(note)"><i
                    class="bi bi-pencil-fill"></i> {{'btn.update' |translate}}</button>
              </div>
            </div>
          </div>
        </div>

        <div style="text-align: right; margin-top: 5px;">
          <button class="btn btn-secondary btn-sm" (click)="newNote()">{{'notebook.addNewNote' | translate}}</button>
        </div>
        <div style="padding:20px 0px;">
          <input [hidden]="editMode == false" id="editNoteTitle" type="text" class="form-control"
            placeholder="{{'noteTitle' | translate}}">
          <hr *ngIf="editMode" />
          <div [hidden]="editMode == false" id="editNoteContent"></div>
          <button *ngIf="editMode && noteEditId != undefined && noteEditId != ''" class="btn btn-sm btn-primary"
            (click)="saveNote()">{{'notebook.save' | translate}}</button>
          <button *ngIf="editMode && (noteEditId == undefined || noteEditId == '')" class="btn btn-sm btn-primary"
            (click)="createNote()">{{'notebook.add' | translate}}</button>
          <button *ngIf="editMode" class="btn btn-sm btn-danger ms-1" (click)="cancelNote()">{{'notebook.cancel' |
            translate}}</button>
        </div>-->
          <lib-note-edit [noteWindow]="noteWindow" [notes]="learnContent.notes" [courseId]="courseId"
            [contentId]="learnContent.currentContent.id"></lib-note-edit>
        </div>
      </div>
      <div [hidden]="selectedTool(4) == false" class="courseContent contentDiv_4">
        <lib-popout-message *ngIf="fileAttachmentWindow != mainPageWindow" [mainWindow]="mainPageWindow"
          [popOutWindow]="fileAttachmentWindow"></lib-popout-message>
        <div class="content_4">
          <app-course-documents [courseDocuments]="learnContent.files"></app-course-documents>
        </div>
      </div>
      <div [hidden]="selectedTool(5) == false" class="courseContent contentDiv_5">
        <lib-popout-message *ngIf="streamWindow != mainPageWindow" [mainWindow]="mainPageWindow"
          [popOutWindow]="streamWindow"></lib-popout-message>
        <div class="content_5">
          <div class="alert alert-warning" role="alert" *ngIf="liveStreams.length < 1">
            <i class="bi bi-broadcast"></i> Karşıdan gelen bir yayın yok.
          </div>
          <div class="liveVideo">
            <div id="miniStreamList"></div>
            <video [hidden]="selectedLiveStream == ''" id="liveVideoStream" autoplay></video>
          </div>
        </div>
      </div>
      <div [hidden]="selectedTool(6) == false" class="courseContent contentDiv_6">
        <lib-popout-message *ngIf="guacamoleScreensWindow != mainPageWindow" [mainWindow]="mainPageWindow"
          [popOutWindow]="guacamoleScreensWindow"></lib-popout-message>
        <div class="content_6">
          <!--<app-course-documents [courseDocuments]="learnContent.files"></app-course-documents>-->
          <lib-guac-multiscreen (showConnectionsRequest)="showConnectedUsers($event)" [vmConnections]="guacamoleVmConnections" [showConnections]="true" (connectVmName)="guacamoleMultiscreenDirectConnect($event)" [disconnect]="guacMultiscreenDisconnect" [sessionJwt]="tabGuacamoleJwt" *ngIf="tabGuacamoleJwt"></lib-guac-multiscreen>
        </div>
      </div>

    </div>
    <!--<div class="objectives col-2" style="white-space: normal;">
    <div class="objectivesTitle">
      Objectives
    </div>
    <div class="objectivesList" >
      <div class="objectiveItemMain"  *ngFor="let objective of learnContent.objectives">
        <div class="objectiveItem">
          <div class="objectiveStatus" style="vertical-align: middle;">
            <i *ngIf="objective.status != null && objective.status == true" class="bi bi-check2"
              style="color: rgb(0, 182, 0); font-size: 20px;"></i>
            <i *ngIf="objective.status != null && objective.status == false" class="bi bi-x"
              style="color: rgb(210, 0, 0); font-size: 20px;"></i>
            <i *ngIf="objective.status ==null || objective.status == undefined" class="bi bi-question"
              style="color: rgb(178, 163, 0); font-size: 20px;"></i>
          </div>
          <div class="objectiveTitle">
            {{objective.title}}
          </div>
        </div>
        <div class="objectiveButtons">
          <i class="bi bi-info-circle-fill" style="color: rgb(0, 142, 0); font-size: 20px; margin-right: 5px;"></i>
          <i class="bi bi-check-circle-fill" style="color: rgb(0, 128, 0); font-size: 20px;"></i>
        </div>
      </div>
    </div>
  </div>-->
    <div class="col-2" style="text-align: center;">
      <div class="rtcVideo">
        <div style="position:relative">
          <lib-rtc-video (closedStream)="handleClosedStream($event)" *ngIf="socketConnected && sessionId != ''"
            [sessionHubObject]="sessionHub" [instructor]="true" [sessionName]="sessionId"
            (secondaryStream)="getLiveStream($event)"></lib-rtc-video>
        </div>
        <!--<div class="row">
        <div class="col-4" (click)="raiseHand()"><i class="bi bi-hand-index-fill raiseHandIcon" [class.active]="isHandRaised" style="font-size:25px;"></i></div>
        <div class="col-4" (click)="voteYes()"><i class="bi bi-check-lg checkIcon" style="font-size:25px;"></i></div>
        <div class="col-4" (click)="voteNo()"><i class="bi bi-x-lg xIcon" style="font-size:25px;"></i></div>
      </div>-->
        <div class="row">
          <div class="col-12">
            <button class="multiScreenButton" (click)="useGuacamoleMultiscreen()"> <i class="bi bi-pip"></i> Çoklu
              Terminal Görünümü <span class="spinner-grow spinner-grow-sm" *ngIf="loadingMultiscreen" role="status"
                aria-hidden="true"></span></button>
          </div>
        </div>
        <div class="row" *ngIf="lastPoll">
          <div class="col-12" style="text-align: left;">
            <font><b>{{'miniPoll' | translate}}</b></font><br />
            <font>{{lastPoll.pollQuestion}}</font>
          </div>
        </div>
        <br />
        <div style="text-align: left;">
          <h5>{{'miniPoll' | translate}}</h5>
          <textarea style="width:100%; margin-bottom: 5px;" class="form-control" id="createPollTextarea"></textarea>
          <div style="text-align: center;">
            <button style="padding-right: 5px; padding-left: 5px; width:45%" (click)="createPoll()"
              class="btn btn-sm btn-primary">{{'miniPoll.ask' | translate}}</button>
            <button data-bs-toggle="modal" data-bs-target="#exampleModal" type="button"
              style="padding-right: 5px; padding-left: 5px; width:45%; margin-left:3px;" (click)="showAllPolls()"
              class="btn btn-sm btn-warning">{{'miniPoll.answers' | translate}}</button>

            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{'miniPoll.answers' | translate}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div class="pollAnswers" *ngFor="let poll of mergedPolls">
                      <p class="pollQuestionText">{{poll.poll.pollQuestion}}</p>
                      <div class="pollVotedUser" *ngFor="let answer of poll.answers">
                        <p class="pollVotedUserName">{{answer.displayName}}</p>
                        <p class="pollVotedUserVote"><i *ngIf="answer.vote" class="bi bi-check voteYes"></i><i
                            *ngIf="answer.vote == false" class="bi bi-x voteNo"></i></p>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{'close' |
                      translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="helpRequests">
            <h5>Yardım İstekleri</h5>
            <div *ngIf="helpRequests.length == 0" class="alert alert-info" role="alert">
              Hiç yardım isteği yok
            </div>
            <div class="helpItem" *ngFor="let helpRequest of helpRequests">
              <div class="helpInfo">
                <div class="helpDisplayName">{{helpRequest.displayName}}</div>
                <div class="helpTerminalName"><i class="bi bi-pc-display"></i> {{helpRequest.terminalId}}</div>
              </div>
              <div class="helpConnect">
                <div style="margin-right: 5px;" class="sharedConnectionConnectBtn helpConnectBtn"
                  (click)="helpRequestConnector(helpRequest.userId,helpRequest.terminalId)"
                  [title]="'connectWithSharedConnection' | translate"><i style="color:rgb(0, 115, 238);"
                    class="bi bi-share-fill"></i></div>

                <div class="sharedConnectionConnectBtn helpConnectBtn"
                  (click)="deleteHelpRequest(helpRequest.userId,helpRequest.terminalId)" [title]="'İsteği Sil'"><i
                    style="color:rgb(157, 0, 0);" class="bi bi-trash-fill"></i></div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div class="learnTools">
        <div class="terminalList">
          <!--  Terminal bağlantısı işlemleri buraya eklenecek-->
        </div>
      </div>
    </div>

  </div>

  <div class="offcanvas offcanvas-end" tabindex="-1" id="userOffcanvas" aria-labelledby="userOffcanvasLabel">
    <div class="offcanvas-header">
      <h5 id="userOffcanvasLabel">{{"virtualLabAssets" | translate}}</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="sharedConnectionItem" *ngFor="let sharedConnection of sharedConnections">
        <div class="sharedConnectionName"><i class="bi bi-display"></i> {{sharedConnection.connectionName}}</div>
        <div *ngIf="isHaveDirectConnect(sharedConnection.connectionName)" class="directConnectionConnectBtn"
          (click)="directConnect(sharedConnection)" [title]="'connectTerminal' | translate"><i
            style="color:rgb(0, 176, 47);" class="bi bi-play-fill"></i></div>
        <div class="sharedConnectionConnectBtn" (click)="connectToSharedConnection(sharedConnection)"
          [title]="'connectWithSharedConnection' | translate"><i style="color:rgb(0, 115, 238);"
            class="bi bi-share-fill"></i></div>
      </div>
    </div>
  </div>
</div>