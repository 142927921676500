import { Observable } from 'rxjs';
import { ApiUrlService } from './../../../../usorta-library/src/lib/services/api/api-url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalendarRequestModel, CalendarResponseModel } from '../models/calendarModel';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  getCalendar(request:CalendarRequestModel):Observable<CalendarResponseModel>{
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.post<CalendarResponseModel>(apiUrl + 'instructor/calendar/mycalendar',request);
  }
}
