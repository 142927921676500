import { Component, Input, OnInit } from '@angular/core';
import { LearnMemberModel } from '../../../models/learnSession/learnMemberModel';

@Component({
  selector: 'app-learn-member',
  templateUrl: './learn-member.component.html',
  styleUrls: ['./learn-member.component.css']
})
export class LearnMemberComponent implements OnInit {
  @Input() learnMember:LearnMemberModel;
  constructor() { }

  ngOnInit(): void {
  }

}
