<div *ngIf="menuItems" class="navbarMain">
  <div class="settingsDiv">
  <div class="btn-group">
    <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
      <i class="bi bi-person-circle"></i>
    </button>
    <ul class="dropdown-menu dropdown-menu-end">
      <li><button class="dropdown-item" (click)="logout()" type="button">{{'logout' | translate}}</button></li>
    </ul>
  </div>
  </div>
  <hr class="settingsHr">
  <div class="navbarContent">
  <div *ngFor="let menuItem of menuItems">
    <div id="{{menuItem.id}}" class="menuitem" *ngIf="!menuItem.subItems || menuItem.subItems.length == 0" [class.active]="isActivePage(menuItem)" (click)="redirect(menuItem)" title="{{menuItem.tooltip}}"><i [class]="menuItem.preIcon"></i> {{menuItem.title}} <i [class]="menuItem.postIcon"></i></div>
    <div class="menuItems" *ngIf="menuItem.subItems && menuItem.subItems.length > 0">
      <div class="menuHeader">{{menuItem.title}}</div>
      <div id="{{subItem.id}}" class="menuitem submenuitem" [class.active]="isActivePage(subItem)" *ngFor="let subItem of menuItem.subItems" title="{{subItem.tooltip}}" (click)="redirect(subItem)"><i [class]="subItem.preIcon"></i> {{subItem.title}} <i [class]="subItem.postIcon"></i></div>
    </div>
  </div>
</div>
</div>
