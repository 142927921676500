<div *ngIf="calendarResponse">
  <div class="calendar" *ngIf="!showPanel">
<div class="month">
  <ul>
    <li>{{'calendar' | translate}}<br><span style="font-size:18px">{{calendarResponse.startDate | formattedDate: "d"}} - {{calendarResponse.endDate | formattedDate: "d"}}</span></li>
  </ul>
</div>

<ul class="weekdays">
  <li>Mo</li>
  <li>Tu</li>
  <li>We</li>
  <li>Th</li>
  <li>Fr</li>
  <li>Sa</li>
  <li>Su</li>
</ul>

<ul class="days" *ngIf="dates != []">
  <li *ngFor="let temp of tempDays" class="tempDay"><div class="tempDayDiv"></div></li>
  <li *ngFor="let date of dates" (click)="showDateEvents(date)"><div class="calendarItemDate">{{date | formattedDate:'sd'}}</div>
  <div class="calendarEvent">
    <i *ngFor="let event of getEventsByDate(date)" [class]="event.eventIcon" title="{{event.eventName}}" style="margin-left:2px;">&nbsp;</i>
    <i *ngIf="getEventsByDate(date).length == 0" class="bi bi-dash-lg" title="{{'calendar.noEvents' | translate}}"></i>
  </div>


  </li>
</ul>
</div>
<div class="panel" *ngIf="showPanel">
  <div class="detailsHeader">
    {{'calendar.dayDetails' | translate}} <br/>
    {{showDate | date: dateFormat}}
  </div>
  <div class="eventDetailsPage">
    <div style="text-align: left;">
      <button class="btn btn-sm btn-secondary" (click)="hideDateDetails()"><i class="bi bi-arrow-left-short"></i> {{'calendar.backCalendar' | translate}}</button>
    </div>
    <br/>
    <div class="eventDetail" *ngIf="showEvents.length == 0"><i class="bi bi-calendar-x"></i>{{'calendar.noEventsOnDate' | translate}}</div>
  <div class="eventDetail" *ngFor="let event of showEvents">
    <div class="row">
      <div class="col-12">
        <div class="eventName">
          {{event.eventName}}
        </div>
        <div class="eventDate">{{event.eventDate | date: dateTimeFormat}}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <img *ngIf="event.picUrl" src="{{event.picUrl}}" width="100%">
        <i *ngIf="!event.picUrl" style="font-size: 4vw;" [class]="event.eventIcon"></i>
      </div>
      <div class="col-10 eventDesc">
        {{event.eventDescription}}
      </div>
    </div>
    <hr style="margin: 0px; margin-bottom:8px; margin-top: 8px;">
    <div class="row">
      <div class="col-12" style="text-align: right;">
        <button class="btn btn-sm btn-secondary" title="{{link.tooltip}}" *ngFor="let link of event.links" (click)="redirect(link.url)">{{link.text}}</button>
      </div>
    </div>
  </div>
</div>
</div>
</div>
