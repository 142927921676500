<lib-preloader *ngIf="showTenantLoader"></lib-preloader>
<div class="cookiePreferencesSelector" *ngIf="showTenantLoader == false">
  <div class="cookiePreferencesBox">
    <lib-cookie-preferences *ngIf="showCookiePreferencesBox"></lib-cookie-preferences>
  </div>

</div>
<div class="mh99">
<div *ngIf="showTenantLoader==false" class="container-fluid">
<div class="row" *ngIf="isLogged && showNavi">
  <div class="col-md-3 col-lg-2" style="position: sticky;">
    <div class="row">
      <div class="col-xs-12">
        <app-navigation-bar *ngIf="menuItems" [menuItems]="menuItems"></app-navigation-bar>
      </div>
    </div>
  </div>

  <div class="col-md-9 col-lg-10">
    <div class="row">
      <div class="col-xs-12">
        <router-outlet *ngIf="showTenantLoader==false"></router-outlet>
      </div>
    </div>
  </div>
</div>
<div  *ngIf="!isLogged || showNavi==false" class="container-fluid">
  <router-outlet *ngIf="showTenantLoader==false"></router-outlet>
</div>
</div>
<lib-footer *ngIf="showTenantLoader == false && showFooter == true"></lib-footer>
</div>

<div class="ref" #modalRef></div>