import { ApiUrlService } from './../../../../usorta-library/src/lib/services/api/api-url.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpcomingCoursesModel } from '../models/upcomingCoursesModel';

@Injectable({
  providedIn: 'root'
})
export class UpcomingSummaryService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  upcomingEvents():Observable<UpcomingCoursesModel[]>{
    let apiUrl = this.apiUrlService.getApiUrl();
    return this.httpClient.get<UpcomingCoursesModel[]>(apiUrl+'instructor/upcoming/courses');
  }
}
