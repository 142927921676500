import { FooterListenerService } from './../../services/listener/footer-listener.service';
import { LoginService } from 'projects/usorta-library/src/lib/services/login/login.service';
import { LoggedUserListenerService } from './../../services/listener/logged-user-listener.service';
import { TenantInfoModel } from './../../models/TenantModels/tenantInfoModel';
import { FooterModel } from '../../models/TenantModels/FooterModels/footerModel';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { ListItemModel } from '../../models/ListItem/listItemModel';
import { NavigationService } from '../../services/navigation/navigation.service';
import { MenuItems } from '../../models/menu/menuItems';
import { environment } from 'projects/usorta-library/src/environments/environment.prod';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  footerModel:FooterModel;
  menuItems:MenuItems[]
  tenantInfoModel:TenantInfoModel;
  constructor(private navigationService:NavigationService,private translateService:TranslateService,private loggedUserListener:LoggedUserListenerService,private loginService:LoginService,private footerListener:FooterListenerService,private router:Router) { }
  languages:ListItemModel[];
  parentOrganization:string;
  showFooterMenu:boolean = true;
  showFooterMenuByUrl:boolean = true;
  ngOnInit(): void {
    this.getAvailableLanguages();
    this.getFooterMenu();
    this.getTenantInfo();
    this.userLoginSubscribe();
    this.footerMenuStatusSubscribe();
  }
  getAvailableLanguages(){
    //this.languages = this.translateService.getLangs();
    var tenantInfo = localStorage.getItem("tenantInfo");
    if(tenantInfo){
      this.languages = JSON.parse(tenantInfo).locales.allowedLocales
      console.log(this.languages);
     // this.pinFooterBottom();
    }
  }
  changeLanguage(lang:string){
    this.translateService.use(lang);
    localStorage.setItem("selectedLanguage",lang);
    //Apiden gelen menü,formlar gibi öğelerin de yeniden yüklenmesi gerekiyor. Bu yüzden sayfayı yeniden yüklemek gerek.
    window.location.reload();
  }
  getCurrentLanguage(){
    return this.translateService.currentLang;
  }
  getFooterMenu(){

    var tenantInfo = localStorage.getItem("tenantInfo");
    if(tenantInfo){
      this.footerModel = JSON.parse(tenantInfo).footer;
      this.navigationService.getMenuByName(this.footerModel.name ?? "footer",environment.applicationId,environment.tenantId).subscribe({
        next:(response)=>{
          this.menuItems = response.content.subItems
        }
      })
     // this.pinFooterBottom();
    }
  }

  getTenantInfo(){
    var tenantInfo = localStorage.getItem("tenantInfo");
    if(tenantInfo){
      this.tenantInfoModel = JSON.parse(tenantInfo).applicationConfiguration;
      this.parentOrganization = this.tenantInfoModel.parentOrganizationTitle;
      console.log(this.tenantInfoModel)
    }
  }
  userLoginSubscribe(){
    this.loggedUserListener.loggedUserStatus$.subscribe(()=>{
      if(this.tenantInfoModel.showFooterMenuOnlyLoggedUsers){
        this.showFooterMenu = this.loginService.isLoggedControlWithExpiration();
      }
    });
  }
  pinFooterBottom(){
    setTimeout(() => {
      var footer = document.getElementById("footer");
      if(footer){
        footer.style.display = "block";
        let footerHeight:number = footer.clientHeight+20;
        footer.style.bottom = "-"+footerHeight + "px";
      }
    },100);


  }
  footerMenuStatusSubscribe(){
    this.footerListener.footerMenuStatus$.subscribe((response)=>{
      if(response == 'false'){
        console.log("response"+response)
        this.showFooterMenu = false;
      }
    }
    );
  }
  routeUser(url:string){
    this.router.navigate([url]);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
