import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GuacAuthService } from '@usorta-packages/guac-library';
import { ToastrService } from 'ngx-toastr';
import { ModalTypes } from 'projects/usorta-library/src/lib/models/ModalManagementModels/modalTypeEnum';
import { GuacamoleSharedConnectionDto } from 'projects/usorta-library/src/lib/models/RemoteClientModels/guacamoleSharedConnectionDto';
import { GlobalModalService } from 'projects/usorta-library/src/lib/services/globalModal/global-modal.service';
import { GuacamoleService } from 'projects/usorta-library/src/lib/services/guacamole/guacamole.service';
import { SessionSocketService } from 'projects/usorta-library/src/lib/services/session/session-socket.service';

@Component({
  selector: 'app-guacamole-multi-terminal',
  templateUrl: './guacamole-multi-terminal.component.html',
  styleUrls: ['./guacamole-multi-terminal.component.css']
})
export class GuacamoleMultiTerminalComponent implements OnInit {
  jwt:string;
  constructor(private guacamoleService:GuacamoleService,private globalModalService:GlobalModalService ,private toastrService:ToastrService, private guacAuthService:GuacAuthService,private activatedRoute:ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next:(param)=>{
        this.jwt = param["jwt"];
      }
    })
  }
  directConnect(vm:GuacamoleSharedConnectionDto){
    //this.router.navigate([']);



    let connectKey = this.generateUUID();
    window.open("/remoteClient/"+this.jwt+"/"+vm.connectionName+"/"+connectKey,'_blank');
  }
  guacamoleMultiscreenDirectConnect(vmName:string){
    this.directConnect({connectionName:vmName,sessionName:"",userId:"",guacSessionId:"",sharedConnectionId:""})
  }
  generateUUID():string { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}
showConnectedUsers(vmName:string){
  var showDiv = document.createElement("div");
  this.guacamoleService.getConnectedUsersWithVmNames([vmName]).subscribe({
    next:(response)=>{
      var users = response[0].connections;
      if(users){
        users.forEach(user=>{
          var userDiv = document.createElement("div");
          userDiv.style.padding = "8px";
          userDiv.style.borderRadius = "12px";
          userDiv.style.background = "#fefefe";
          userDiv.style.marginBottom = "3px";
          var userIcon = document.createElement("i");
          userIcon.classList.add("bi","bi-person");
          var username = document.createElement("b");
          username.innerText = " "+user.username;
          userDiv.append(userIcon);
          userDiv.append(username);
          showDiv.append(userDiv);
        })
      }
      this.globalModalService.showModal({
        data:showDiv,
        helperModals:[],
        title:"Active Users",
        type:ModalTypes.HTMLELEMENT
      })
    },error:(err)=>{

    }
  })
}

}
