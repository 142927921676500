<div class="sessionEndedMain">
  <div class="sessionEnded">
  <div class="logo">
    <img *ngIf="logo" class="imglogo" [src]="logo">
  </div>
  <!--
  Oturum sonu sayfasına dışarıdan bir veri alınmak istenirse bu loaderi kullanabiliriz.
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  -->
  <div class="d-flex justify-content-center">
    <div style="text-align: center;">
    <p class="endText">Oturum Tamamlandı</p>
    <button class="btn btn-danger" routerLink="/"><i class="bi bi-house"></i> {{"mainPage" | translate}}</button>
    </div>
  </div>
</div>
</div>
