import { Observable } from 'rxjs';
import { ApiUrlService } from './../../../../usorta-library/src/lib/services/api/api-url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavbarMenuItem } from '../models/navbarMenuItem';

@Injectable({
  providedIn: 'root'
})
export class NavigationBarService {

  constructor(private httpClient:HttpClient,private apiUrlService:ApiUrlService) { }
  getMenu():Observable<NavbarMenuItem[]>{
    return this.httpClient.get<NavbarMenuItem[]>(this.apiUrlService.getApiUrl()+'instructor/navbar/getmenu');
  }
}
