<div class="row">
  <div class="col-md-6">
    <div class="row">
      <div class="col-xs-12">
        <h4>{{'upcomingCoursesTitle' | translate}}</h4>
        <app-instructor-courses-list *ngIf="upcomingCourses" [upcomingCourses]="upcomingCourses"></app-instructor-courses-list>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="row">
      <div class="col-xs-12">
        <div style="margin-bottom: 20px;">
          <app-messages-summary></app-messages-summary>
        </div>
        <div>
          <app-calendar></app-calendar>
        </div>
      </div>
    </div>
  </div>
</div>

