import { Component, Input, OnInit } from '@angular/core';
import { UpcomingCoursesModel } from '../../models/upcomingCoursesModel';

@Component({
  selector: 'app-instructor-courses-list',
  templateUrl: './instructor-courses-list.component.html',
  styleUrls: ['./instructor-courses-list.component.css']
})
export class InstructorCoursesListComponent implements OnInit {
  @Input() upcomingCourses:UpcomingCoursesModel[];
  constructor() { }

  ngOnInit(): void {
  }

}
